import { MatchReconcileComponent } from '@components/module/reconcile/payment-point/match-reconcile.component';
import UnauthorizedPage from '@pages/404.page';
import { Role } from '@shared/constants';
import { getAuthData } from '@store/entities/auth';
import React from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

const MatchReconcilePage = () => {
  const { auth } = useSelector(getAuthData);
  const role = auth?.UserRole[0]?.name;
  let renderPage = <UnauthorizedPage />;
  const location = useLocation();

  if (auth) {
    switch (role) {
      case Role?.USER_SUPER_ADMIN:
      case Role?.USER_ADMIN_PARTNER:
        if (location.search === "?success") {
          renderPage = <MatchReconcileComponent auth={auth} isSuccess={true} />
        } else {
          renderPage = <MatchReconcileComponent auth={auth} isSuccess={false} />
        }
        break;
      default:
        break;
    }
  }

  return (<>{renderPage}</>)
}

export default MatchReconcilePage