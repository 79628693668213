import { ListUserRequestComponent } from '@components/module/user/request/user-request-list.component';
import UnauthorizedPage from '@pages/404.page';
import { Role } from '@shared/constants';
import { getAuthData } from '@store/entities/auth';
import { fetchAllRole } from '@store/entities/role';
import { fetchRequestUser } from '@store/entities/user';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

const ListUserRequestPage = () => {
  const { auth } = useSelector(getAuthData);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const role = auth?.UserRole[0]?.name;
  let renderPage = <UnauthorizedPage />;
  const dispatch = useDispatch();

  useEffect(() => {
    const params = {
      limit: perPage,
      page: page,
    }
    dispatch(fetchRequestUser(params));
    dispatch(fetchAllRole({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setPerPage(newPerPage);
    setPage(page);
  };


  if (auth) {
    switch (role) {
      case Role?.USER_SUPER_ADMIN:
        renderPage = <ListUserRequestComponent auth={auth} handlePerRowsChange={handlePerRowsChange} handlePageChange={handlePageChange} />
        break;
      default:
        break;
    }
  }

  return <>{renderPage}</>
}

export default ListUserRequestPage;