import InquiryMonitoringPage from "@pages/monitor/inquiry/inquiry.page";
import CommitMonitoringPage from "@pages/monitor/commit/commit.page";
import PaymentMonitoringPage from "@pages/monitor/payment/payment.page";
import CicoMonitoringPage from "@pages/monitor/cico/cico.page";
import CashInVirgoPage from "@pages/monitor/cico/ci-virgo.page";
import PurchaseMonitoringPage from "@pages/monitor/purchase/purchase.page";
import MonitoringDepositPage from "@pages/monitor/deposit/deposit.page";
import MonitoringWithdrawalPage from "@pages/monitor/withdrawal/withdrawal.page";

export const monitoringRoute = [
  {
    path: "/monitoring/inquiry/*",
    element: <InquiryMonitoringPage />,
    isAuth: true,
  },
  {
    path: "/monitoring/commit/*",
    element: <CommitMonitoringPage />,
    isAuth: true,
  },
  {
    path: "/monitoring/payment/*",
    element: <PaymentMonitoringPage />,
    isAuth: true,
  },
  {
    path: "/monitoring/purchase/*",
    element: <PurchaseMonitoringPage />,
    isAuth: true,
  },
  {
    path: "/monitoring/cash/in",
    element: <CicoMonitoringPage type={"in"} />,
    isAuth: true,
  },
  {
    path: "/monitoring/cash/in/virgo",
    element: <CashInVirgoPage />,
    isAuth: true,
  },
  {
    path: "/monitoring/cash/out",
    element: <CicoMonitoringPage type={"out"} />,
    isAuth: true,
  },
  {
    path: "/monitoring/deposit",
    element: <MonitoringDepositPage />,
    isAuth: true,
  },
  {
    path: "/monitoring/withdrawal",
    element: <MonitoringWithdrawalPage/>,
    isAuth: true,
  }
]