export const columnsListReconcileSuccess = [
  {
    name: "Id",
    selector: (row: any) => row.id,
    sortable: true,
  },
  {
    name: "Reconcile Id",
    selector: (row: any) => row.recon_id,
    sortable: true,
  },
  {
    name: "Agent Trx Id",
    selector: (row: any) => row.agent_trx_id,
    sortable: true,
  },
  {
    name: "Agent Id",
    selector: (row: any) => row.agent_id,
    sortable: true,
  },
  {
    name: "Agent Store Id",
    selector: (row: any) => row.agent_store_id,
    sortable: true,
  },
  {
    name: "Customer Id",
    selector: (row: any) => row.customer_id,
    sortable: true,
  },
  {
    name: "Date Time Request",
    selector: (row: any) => row.date_time_request,
    sortable: true,
  },
  {
    name: "Amount",
    selector: (row: any) => row.amount,
    sortable: true,
  },
  {
    name: "Fee",
    selector: (row: any) => row.fee,
    sortable: true,
  },
  {
    name: "Ref Code",
    selector: (row: any) => row.ref_code,
    sortable: true,
  },
  {
    name: "Company",
    selector: (row: any) => row.company,
    sortable: true,
  },
  {
    name: "Partner Name",
    selector: (row: any) => row.partner_name,
    sortable: true,
  },
  {
    name: "Trx Dt",
    selector: (row: any) => row.trx_dt,
    sortable: true,
  }, {
    name: "Created By",
    selector: (row: any) => row.created_by,
    sortable: true,
  }, {
    name: "Created At",
    selector: (row: any) => row.created_at,
    sortable: true,
  }, {
    name: "Updated By",
    selector: (row: any) => row.updated_by,
    sortable: true,
  }, {
    name: "Updated At",
    selector: (row: any) => row.updated_at,
    sortable: true,
  }, {
    name: "Alfa Algo Matched Status",
    selector: (row: any) => row.alfa_algo_matched_status,
    sortable: true,
  },
]

export const columnListReconcile = [
  {
    name: "Id",
    selector: (row: any) => row.reconcile_id,
    sortable: true,
  },
  {
    name: "File Name",
    selector: (row: any) => row.file_name,
    sortable: true,
  },
  {
    name: "Matched Record",
    selector: (row: any) => row.matched_record,
    sortable: true,
  },
  {
    name: "Reconcile Local Datetime",
    selector: (row: any) => row.recon_local_datetime,
    sortable: true,
  },
  {
    name: "Total Record",
    selector: (row: any) => row.total_record,
    sortable: true,
  },
  {
    name: "Unmatched Record",
    selector: (row: any) => row.unmatched_record,
    sortable: true,
  },
]

export const columnMatchReconcile = [
  {
    name: "Number Of Payment Data",
    selector: (row: any) => row.number_of_payment_data,
  },
  {
    name: "Number Of Reconcile Data",
    selector: (row: any) => row.number_of_reconcile_data,
  },
  {
    name: "Number Of Success",
    selector: (row: any) => row.number_of_success,
  },
  {
    name: "Number Of Failed",
    selector: (row: any) => row.number_of_failed,
  }
]

export const columnMatchListReconcileSuccessCA = [
  {
    name: "Transaction Id",
    selector: (row: any) => row.transaction_id,
    sortable: true,
  },
  {
    name: "Agent Trx Id",
    selector: (row: any) => row.agent_trx_id,
    sortable: true,
  },
  {
    name: "State",
    selector: (row: any) => row.state,
    sortable: true,
  },
  {
    name: "Response Code",
    selector: (row: any) => row.response_code,
    sortable: true,
  },
  {
    name: "Transaction Date",
    selector: (row: any) => row.transaction_date,
    sortable: true,
  }
]