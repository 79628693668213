export const formPurchase: any = [
  {
    name: "transaction_id",
    type: "text",
    label: "Transaction Id",
    placeholder: "e.g 12321313",
  },
  {
    name: "product_id",
    type: "text",
    label: "Product ID",
    placeholder: "e.g 12314313",
  },
  {
    name: "customer_number",
    type: "text",
    label: "Customer Number",
    placeholder: "e.g 12314313",
  },

]