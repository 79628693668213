import { Button } from "@components/common"
import { Link } from "react-router-dom"

export const depositToken = {
  name: "Deposit Trx Id",
  selector: (row: any) => row.deposit_transaction_id,
  sortable: true,
}

export const withdrawalToken = {
  name: "Withdrawal Token",
  selector: (row: any) => row.withdrawal_token,
  sortable: true,
}

export const columnMonitoring = [
  {
    name: "Expired Token Time",
    selector: (row: any) => row.expired_token_time,
    sortable: true,
  },
  {
    name: "Created By",
    selector: (row: any) => row.created_by,
    sortable: true,
  },
  {
    name: "Created Time",
    selector: (row: any) => row.created_time,
    sortable: true,
  },
  {
    name: "Updated By",
    selector: (row: any) => row.updated_by,
    sortable: true,
  },
  {
    name: "Updated Time",
    selector: (row: any) => row.updated_time,
    sortable: true,
  },
  {
    name: "Created Token Time",
    selector: (row: any) => row.created_token_time,
    sortable: true,
  },
]

export const columnCico = [
  {
    name: "Terminal ID",
    selector: (row: any) => row.terminal_id,
    sortable: true,
  },
  {
    name: "Bank Name",
    selector: (row: any) => row.bank_name,
    sortable: true,
  },
  {
    name: "Bank ID",
    selector: (row: any) => row.bank_id,
    sortable: true,
  },
  {
    name: "Merchant Request ID",
    selector: (row: any) => row.merchant_request_id,
    sortable: true,
  },
  {
    name: "Amount",
    selector: (row: any) => row.amount,
    sortable: true,
  },
  {
    name: "Decimal Point",
    selector: (row: any) => row.decimal_point,
    sortable: true,
  },
  {
    name: "Currency",
    selector: (row: any) => row.currency,
    sortable: true,
  },
  {
    name: "Status",
    selector: (row: any) => row.status,
    sortable: true,
  },
  {
    name: "Bank State",
    selector: (row: any) => row.bank_state,
    sortable: true,
  },
  {
    name: "Bank Endpoint Host",
    selector: (row: any) => row.bank_endpoint_host,
    sortable: true,
  },
  {
    name: "Service Fee",
    selector: (row: any) => row.service_fee,
    sortable: true,
  },
  {
    name: "Customer Fee",
    selector: (row: any) => row.customer_fee,
    sortable: true,
  },
  {
    name: "System Expired Token Time",
    selector: (row: any) => row.system_expired_token_time,
    sortable: true,
  },
  {
    name: "Account Number",
    selector: (row: any) => row.account_number,
    sortable: true,
  },
  {
    name: "Sender Name",
    selector: (row: any) => row.sender_name,
    sortable: true,
  },
  {
    name: "Recipient Name",
    selector: (row: any) => row.recipient_name,
    sortable: true,
  },
  {
    name: "ID Type",
    selector: (row: any) => row.id_type,
    sortable: true,
  },
  {
    name: "ID Number",
    selector: (row: any) => row.id_number,
    sortable: true,
  },
  {
    name: "Attempt Status",
    selector: (row: any) => row.attempt_status,
    sortable: true,
  },
  {
    name: "Discount Fee",
    selector: (row: any) => row.discount_fee,
    sortable: true,
  },
]

export const columnCashIn = [
  {
    name: "Deposit Trx ID",
    selector: (row: any) => row.deposit_transaction_id,
    sortable: true,
  },
  {
    name: "Last Commit Attempt",
    selector: (row: any) => row.last_commit_attempt,
    sortable: true,
  },
  {
    name: "Next Commit Attempt",
    selector: (row: any) => row.next_commit_attempt,
    sortable: true,
  },
  {
    name: "Commit Attempt Count",
    selector: (row: any) => row.commit_attempt_count,
    sortable: true,
  },
  {
    name: "Max Commit Attempt Count",
    selector: (row: any) => row.max_commit_attempt_count,
    sortable: true,
  },
]

export const columnCashOut = [
  {
    name: "Withdrawal Trx ID",
    selector: (row: any) => row.withdrawal_transaction_id,
    sortable: true,
  },
  {
    name: "Last Reversal Attempt",
    selector: (row: any) => row.last_reversal_attempt,
    sortable: true,
  },
  {
    name: "Next Reversal Attempt",
    selector: (row: any) => row.next_reversal_attempt,
    sortable: true,
  },
  {
    name: "Reversal Attempt Count",
    selector: (row: any) => row.reversal_attempt_count,
    sortable: true,
  },
  {
    name: "Max Reversal Attempt Count",
    selector: (row: any) => row.max_reversal_attempt_count,
    sortable: true,
  },
  {
    name: "Reversal Endpoint Host",
    selector: (row: any) => row.reversal_endpoint_host,
    sortable: true,
  },
  {
    name: "Reversal Request",
    selector: (row: any) => row.reversal_request,
    sortable: true,
  },
  {
    name: "Reversal Response",
    selector: (row: any) => row.reversal_response,
    sortable: true,
  },
  {
    name: "Withdrawal Reversal ID",
    selector: (row: any) => row.withdrawal_reversal_id,
    sortable: true,
  },
]

export const columnHeaderCico = [
  {
    ignoreRowClick: true,
    button: true,
    cell: (row: any) => (
      <>
        <Link to={`/reconcile/cico/${row.reconcile_id}`}>
          <Button mode="secondary" style={{ padding: "5px 10px" }}>
            More
          </Button>
        </Link>
      </>
    ),
    center: true,
    maxWidth: "10px"
  },
  {
    name: "Deposit Record",
    selector: (row: any) => row.deposit_record,
    value: "deposit_record",
    sortable: true,
  },
  {
    name: "File Name",
    selector: (row: any) => row.file_name,
    value: "file_name",
    sortable: true,
  },
  {
    name: "Matched Deposit Record",
    selector: (row: any) => row.matched_deposit_record,
    value: "matched_deposit_record",
    sortable: true,
  },
  {
    name: "Matched Withdrawal Record",
    selector: (row: any) => row.matched_withdrawal_record,
    value: "matched_withdrawal_record",
    sortable: true,
  },
  {
    name: "Reconcile ID",
    selector: (row: any) => row.reconcile_id,
    value: "reconcile_id",
    sortable: true,
  },
  {
    name: "Total Record",
    selector: (row: any) => row.total_record,
    value: "total_record",
    sortable: true,
  },
  {
    name: "Unmatched Deposit Record",
    selector: (row: any) => row.unmatched_deposit_record,
    value: "unmatched_deposit_record",
    sortable: true,
  },
  {
    name: "Unmatched Withdrawal Record",
    selector: (row: any) => row.unmatched_withdrawal_record,
    value: "unmatched_withdrawal_record",
    sortable: true,
  },
  {
    name: "Recon Local Datetime",
    selector: (row: any) => row.recon_local_datetime,
    value: "recon_local_datetime",
    sortable: true,
    width: "15rem"
  },
  {
    name: "Updated At",
    selector: (row: any) => row.updated_at,
    value: "updated_at",
    sortable: true,
    width: "15rem"
  },
  {
    name: "Withdrawal Record",
    selector: (row: any) => row.withdrawal_record,
    value: "withdrawal_record",
    sortable: true,
  },
  {
    name: "Withdrawal Record",
    selector: (row: any) => row.withdrawal_record,
    value: "withdrawal_record",
    sortable: true,
  },
]

export const columnDetailCico = [
  {
    name: "ID",
    selector: (row: any) => row.id,
    sortable: true,
  },
  {
    name: "Recon ID",
    selector: (row: any) => row.recon_id,
    sortable: true,
  },
  {
    name: "Agent Trx ID",
    selector: (row: any) => row.agent_trx_id,
    sortable: true,
  },
  {
    name: "Transaction Date",
    selector: (row: any) => row.transaction_date,
    sortable: true,
  },
  {
    name: "Customer Key",
    selector: (row: any) => row.customer_key,
    sortable: true,
  },
  {
    name: "Customer Name",
    selector: (row: any) => row.customer_name,
    sortable: true,
  },
  {
    name: "Denom",
    selector: (row: any) => row.denom,
    sortable: true,
  },
  {
    name: "Admin Fee",
    selector: (row: any) => row.admin_fee,
    sortable: true,
  },
  {
    name: "Amount",
    selector: (row: any) => row.amount,
    sortable: true,
  },
  {
    name: "Ref ID",
    selector: (row: any) => row.ref_id,
    sortable: true,
  },
  {
    name: "Transaction Type",
    selector: (row: any) => row.transaction_type,
    sortable: true,
  },
  {
    name: "Alfa Status",
    selector: (row: any) => row.alfa_status,
    sortable: true,
  },
  {
    name: "Alfa ALGO Matched Status",
    selector: (row: any) => row.alfa_algo_matched_status,
    sortable: true,
  },
  {
    name: "Created By",
    selector: (row: any) => row.created_by,
    sortable: true,
  },
  {
    name: "Created At",
    selector: (row: any) => row.created_at,
    sortable: true,
  },
  {
    name: "Updated By",
    selector: (row: any) => row.updated_by,
    sortable: true,
  },
  {
    name: "Updated At",
    selector: (row: any) => row.updated_at,
    sortable: true,
  },
]

export const columnCashInVirgo = [
  {
    width: "12ch",
    name: "Inquiry ID",
    selector: (row: any) => row.inquiry_id,
    sortable: true,
  },
  {
    width: "30ch",
    name: "Transaction ID",
    selector: (row: any) => row.transaction_id,
    sortable: true,
  },
  {
    width: "30ch",
    name: "Alfa Trx ID",
    selector: (row: any) => row.alfa_tx_id,
    sortable: true,
  },
  {
    name: "Alfa Trx ID",
    selector: (row: any) => row.alfa_tx_id,
    sortable: true,
  },
  {
    name: "Partner Ref No.",
    selector: (row: any) => row.partner_reference_no,
    sortable: true,
  },
  {
    width: "25ch",
    name: "Customer ID",
    selector: (row: any) => row.customer_id,
    sortable: true,
  },
  {
    name: "Customer Name",
    selector: (row: any) => row.customer_name,
    sortable: true,
  },
]

export const columnCashInVirgoLog = [
  {
    name: "Id",
    selector: (row: any) => row.id,
    sortable: true,
  },
  {
    name: "Reference Number",
    selector: (row: any) => row.reference_no,
    sortable: true,
  },
  {
    name: "Request Type",
    selector: (row: any) => row.request_type,
    sortable: true,
  },
  {
    name: "Req From CA",
    selector: (row: any) => row.request_form_ca,
    sortable: true,
  },
  {
    name: "Req From CA Timestamp",
    selector: (row: any) => row.request_form_ca,
    sortable: true,
  },
  {
    name: "Res To CA",
    selector: (row: any) => row.response_to_ca,
    sortable: true,
  },
  {
    name: "Res To CA Timestamp",
    selector: (row: any) => row.response_to_ca_timestamp,
    sortable: true,
  },
  {
    name: "Req To Partner",
    selector: (row: any) => row.request_to_partner,
    sortable: true,
  },
  {
    name: "Req To Partner Timestamp",
    selector: (row: any) => row.request_to_partner_timestamp,
    sortable: true,
  },
  {
    name: "Res From Partner",
    selector: (row: any) => row.response_from_partner,
    sortable: true,
  },
  {
    name: "Res From Partner Timestamp",
    selector: (row: any) => row.response_from_partner_timestamp,
    sortable: true,
  },
  {
    name: "Created At",
    selector: (row: any) => row.created_at,
    sortable: true,
  },
  {
    name: "Update At",
    selector: (row: any) => row.update_at,
    sortable: true,
  },
]