import { IFormProps } from '@shared/interface/form.interface';
import { Form, Formik } from 'formik';
import React, { Key } from 'react'
import { Alert, Modal } from 'react-bootstrap'
import { Button, InputField } from '..';
interface IModalFormComponent {
  show: boolean;
  onHide?: () => void;
  title?: string;
  initialValue?: any;
  onSubmit: (e: any) => void;
  formData?: any;
  validationSchema?: any;
  alertMessage?: any;
  children?: any;
  callbackSelect?: any;
}
export const ModalFormComponent = (props: IModalFormComponent) => {
  const {
    show,
    onHide,
    title,
    initialValue,
    onSubmit,
    formData,
    validationSchema,
    alertMessage,
    callbackSelect
  } = props;
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
    >
      <Modal.Header closeButton>
        {title}
      </Modal.Header>
      <Formik initialValues={initialValue} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ errors }) => {
          return (
            <Form>
              <Modal.Body>
                {formData?.map((data: IFormProps, i: Key) => (
                  <InputField
                    field={data}
                    errors={errors}
                    key={i}
                    callbackSelect={callbackSelect}
                  />
                ))}
                {alertMessage &&
                  <Alert variant={"danger"}>{alertMessage}</Alert>
                }
              </Modal.Body>
              <Modal.Footer>
                <Button type="submit" mode={"primary"} >Submit</Button>
                <Button type="button" onClick={onHide} mode={"danger"}>Cancel</Button>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  )
}