import { api_url } from "@shared/constants";
import { assemblyParameters } from "@shared/functions";
import axios from "axios";

const url = process.env.REACT_APP_API_URL;
const tokenLocal = localStorage.getItem("AID-T");
const token = tokenLocal ? JSON.parse(atob(tokenLocal)) : "";

export const api = axios.create({
  baseURL: url,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export const fetchTransaction = async (data: any) => {
  const { module, type, moduleType, page, limit, ...rest } = data;
  const params = {
    ...rest,
    page: page ? page : 1,
    limit: limit ? limit : 10
  }
  const paramQuery = assemblyParameters(params);
  try {
    const res = await api.get(`${api_url}/v1/${module}/${type}/${moduleType}?${paramQuery}`);
    return res;
  } catch (error: any) {
    return error.response;
  }
}

export const putChangePassword = async (data: any) => {
  try {
    const res = await api.put(`${api_url}/v1/users/change-password`, data);
    return res;
  } catch (error: any) {
    return error.response;
  }
}

export const downloadCSV = async (data: any) => {
  const { module, type_org, ...rest } = data;
  const paramQuery = assemblyParameters(rest);
  try {
    const res = await api.get(`${api_url}/v1/reporting/${type_org}/${module}/download?${paramQuery}`);
    return res;
  } catch (error: any) {
    return error.response;
  }
}

export const createRequest = async (data: any) => {
  try {
    const res = await api.post(`${api_url}/v1/users/requests`, data);
    return res;
  } catch (error: any) {
    return error.response;
  }
}

export const createApproveRequest = async (data: any) => {
  const { id, ...rest } = data;
  try {
    const res = await api.post(`${api_url}/v1/users/requests/${id}/approve`, rest);
    return res;
  } catch (error: any) {
    return error.response;
  }
}

export const fetchProductList = async (params: any) => {
  try {
    const paramQuery = assemblyParameters(params);
    const res = await api.get(`${api_url}/v1/users/products/list?${paramQuery}`);
    return res;
  } catch (error: any) {
    return error.response;
  }
}

export const fetchListReconcile = async (params: any) => {
  try {
    const { isSuccess, ...rest } = params;
    const paramQuery = assemblyParameters(rest);
    const res = await api.get(`${api_url}/v1/reconcile/ca/list${isSuccess ? "/success" : ""}?${paramQuery}`)
    return res;
  } catch (error: any) {
    return error.response;
  }
}

export const fetchMatchReconcile = async (params: any) => {
  try {
    const { isSuccess, ...rest } = params;
    const paramQuery = assemblyParameters(rest);
    const res = await api.get(`${api_url}/v1/reconcile/ca/match${isSuccess ? "/list/success" : ""}?${paramQuery}`);
    return res;
  } catch (error: any) {
    return error.response;
  }
}

export const fetchListCico = async (params: any) => {
  try {
    const { type, module, ...rest } = params;
    const paramQuery = assemblyParameters(rest);
    const res = await api.get(`${api_url}/v1/${module}/cash/${type}?${paramQuery}`);
    return res;
  } catch (error: any) {
    return error.response;
  }
}

export const downloadCicoCsv = async (params: any) => {
  try {
    const { type, ...rest } = params;
    const paramQuery = assemblyParameters(rest);
    const res = await api.get(`${api_url}/v1/reporting/cash/${type}/download?${paramQuery}`);
    return res;
  } catch (error: any) {
    return error.response;
  }
}

export const fetchBank = async () => {
  try {
    const res = await api.get(`${api_url}/v1/users/banks`);
    return res;
  } catch (error: any) {
    return error.response;
  }
}

// export const fetchReconcileCicoDetail = async (params: any) => {
//   try {
//     const { type, ...rest } = params;
//     const res = await api.get(`${api_url}/v1/${type}/reconciliations?${rest}`);
//     return res;
//   } catch (error: any) {
//     return error.response;
//   }
// }

export const triggerSchedulerReconcile = async (params: any) => {
  try {
    const paramQuery = assemblyParameters(params);
    const res = await api.get(`${api_url}/v1/reconcile/specific/date?${paramQuery}`);
    return res;
  } catch (error: any) {
    return error.response;
  }
}

// Insert Reconcile CICO Custom Bank ALGO To PARTNER
export const schedulerRCAlgoToPartner = async (data: any) => {
  try {
    const res = await api.post(`${api_url}/v1/reconcile/cico/custom`, data);
    return res;
  } catch (error: any) {
    return error.response;
  }
}

// Insert Reconcile CICO Custom Alfa to Algo
export const schedulerRCAlfaToAlgo = async (data: any) => {
  try {
    const res = await api.post(`${api_url}/v1/reconcile/cico/custom/ca-algo`, data);
    return res;
  } catch (error: any) {
    return error.response;
  }
}


// Insert Reconcile CICO Custom Partner to Algo
export const schedulerRCPartnerToAlgo = async (data: any) => {
  try {
    const res = await api.post(`${api_url}/v1/reconcile/cico/partner-algo/bank`, data);
    return res;
  } catch (error: any) {
    return error.response;
  }
}

export const fetchPermissions = async () => {
  try {
    const res = await api.get(`${api_url}/v1/permissions`);
    return res;
  } catch (error: any) {
    return error.response;
  }
}

export const postCreateRole = async (body: any) => {
  try {
    const res = await api.post(`${api_url}/v1/roles`, body);
    return res;
  } catch (error: any) {
    return error.response
  }
}

export const putUpdateRole = async (body: any) => {
  try {
    const { id, ...rest } = body;
    const res = await api.put(`${api_url}/v1/roles/${id}`, rest);
    return res;
  } catch (error: any) {
    return error.response
  }
}

export const fetchRoles = async (id?: any) => {
  try {
    const res = await api.get(`${api_url}/v1/roles${id ? `/${id}` : ""}`);
    return res;
  } catch (error: any) {
    return error.response;
  }
}

export const deleteRoles = async (id: number) => {
  try {
    const res = await api.delete(`${api_url}/v1/roles/${id}`, { data: { role_id: id } });
    return res;
  } catch (error: any) {
    return error.response;
  }
}

// TOPUP VIRGO
export const postMonitoringTopup = async (body: any) => {
  try {
    const res = await api.post(`${api_url}/v1/monitoring/topup`, body);
    return res;
  } catch (error: any) {
    return error.response;
  }
}

export const postMonitoringTopUpLog = async (body: any) => {
  try {
    const res = await api.post(`${api_url}/v1/monitoring/topup/log`, body);
    return res;
  } catch (error: any) {
    return error.response;
  }
}