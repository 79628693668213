import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

export const BackButton = () => {
  const navigate = useNavigate();

  return (
    <BiArrowBack className="h-auto width30 mr-3 c-pointer" onClick={() => navigate(-1)}/>
  );
};
