import { CicoComponent } from '@components/module/cico/cico.component';
import { columnCashIn, columnCashOut, columnCico } from '@components/module/cico/data-tables/columns.cico';
import UnauthorizedPage from '@pages/404.page';
import { MONITORING, Role } from '@shared/constants';
import { getAuthData } from '@store/entities/auth';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const CicoMonitoringPage = (props: any) => {
  const { type } = props;
  const { auth } = useSelector(getAuthData);
  const role = auth?.UserRole[0]?.name;
  const [column, setColumn] = useState<any>([]);
  let renderPage = <UnauthorizedPage />

  useEffect(() => {
    const newColumn = type === "in" ? columnCashIn : columnCashOut;
    // const tokenColumn = type === "in" ? depositToken : withdrawalToken;
    setColumn([...newColumn, ...columnCico]);
  }, [type]);


  if (auth) {
    switch (role) {
      case Role?.USER_SUPER_ADMIN:
        renderPage = <CicoComponent auth={auth} module={MONITORING} type={type} column={column} />
        break;
      default:
        break;
    }
  }
  return <>{renderPage}</>
}

export default CicoMonitoringPage