import { PurchaseComponent } from '@components/module/reconcile/purchase/purchase.component';
import UnauthorizedPage from '@pages/404.page';
import { Role } from '@shared/constants';
import { getAuthData } from '@store/entities/auth';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

const PurchasePage = () => {
  // const { type } = props;
  const { auth } = useSelector(getAuthData);
  const role = auth?.UserRole[0]?.name;
  const [column, setColumn] = useState<any>([]);
  let renderPage = <UnauthorizedPage />

  // useEffect(() => {
  //   // const newColumn = type === "in" ? columnCashIn : columnCashOut;
  //   // const tokenColumn = type === "in" ? depositToken : withdrawalToken;
  //   setColumn([...newColumn, ...columnCico]);
  // }, [type]);


  if (auth) {
    switch (role) {
      case Role?.USER_SUPER_ADMIN:
        renderPage = <PurchaseComponent auth={auth} column={column} />
        break;
      default:
        break;
    }
  }
  return <>{renderPage}</>
}

export default PurchasePage