import { TableComponent } from "@components/tables/table.component";
import { postMonitoringTopUpLog } from "@shared/api";
import React, { useEffect, useState } from "react";
import { columnCashInVirgoLog } from "../data-tables/columns.cico";

export const TopUpVirgoLog = () => {
  const [listData, setListData] = useState<any>();
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const fetchLogTopUp = async () => {
    const body = {
      limit: perPage,
      page,
    };
    const res = await postMonitoringTopUpLog(body);
    if (res?.status === 200) {
      setListData(res?.data);
    }
  };

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setPerPage(newPerPage);
    setPage(page);
  };

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  useEffect(() => {
    fetchLogTopUp();
  }, [perPage, page]);

  return (
    <>
      <TableComponent
        title="Log Virgo Topup"
        data={listData?.data ? listData?.data : []}
        columns={columnCashInVirgoLog}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        paginationTotalRows={listData?.total_row}
      />
    </>
  );
};
