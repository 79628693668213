import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import Table from 'react-data-table-component';

export const ListPermissionComponent = (props: any) => {
  const data_role = [
    {
      name: "inquiry",
      modulePage: "payment-point.monitoring.ca.inquiry",
      desc: "Full control of content management",
      sub_data: [
        {
          name: "read",
          desc: "Access read content",
        },
        {
          name: "update",
          desc: "Access update content",
        },
        {
          name: "create",
          desc: "Access create content",
        },
        {
          name: "delete",
          desc: "Access delete content",
        },
      ]
    },
    {
      name: "commit",
      modulePage: "payment-point.monitoring.ca.commit",
      desc: "Full control of outlet",
      sub_data: [
        {
          name: "All",
          desc: "Access read role",
        },
        {
          name: "read",
          desc: "Access read content",
        },
      ]
    },
  ]

  const data = data_role.map((data: any) => { return { name: data?.name, page: data?.modulePage, action: data?.sub_data?.map((data: any) => data?.name) } })

  const columnn = [
    {
      name: "Name",
      selector: (row: any) => row?.name,
      sortable: true,
      center: true,
    },
    {
      name: "Page",
      selector: (row: any) => row?.page,
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: (row: any) => row?.action?.map((data: any) => (<span>{data}, </span>)),
      sortable: true,
      center: true,
    },
  ]
  return (
    <Row>
      <Col>
        <Card>
          <Card.Header>
            <h3>Permission</h3>
          </Card.Header>
          <Card.Body>
            <Table columns={columnn} data={data} />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}
