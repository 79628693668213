import { Button } from '@components/common';
import { triggerSchedulerReconcile } from '@shared/api';
import { getDate } from '@shared/functions';
import React, { useState } from 'react'
import { Alert, Card, Col, Row } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import { CicoReconcileComponent } from '../cico/cico-recon.component';

export const SchedulerReconcileComponent = () => {
  const [date, setDate] = useState(null);
  const [msg, setMsg] = useState<any>();

  const handleSubmit = async () => {
    const res = await triggerSchedulerReconcile({ date: getDate(date) });
    if (res.status === 200) {
      setMsg({ success: res.data?.message || "" });
    } else {
      setMsg({ failed: res?.data?.message || "Error" });
    }
  }

  
  return (
    <>
      <Row>
        <Col xs={12} sm={12}>
          <Card>
            <Card.Header>Payment Point Scheduler</Card.Header>
            <Card.Body>
              <Row className="mb-3">
                <Col xs={12} md={3}>
                  <label>Date Transaction</label>
                  <DatePicker
                    className={`form-control`}
                    dateFormat="yyyy-MM-dd"
                    placeholderText={"Select Date"}
                    selected={date}
                    onChange={(date: any) => {
                      setDate(date);
                    }}
                    isClearable={true}
                  />
                </Col>
              </Row>
              {msg &&
                <Alert className="mt-4" style={{ width: "fit-content" }} variant={msg?.success !== "" ? "success" : "danger"} >{msg.success}{msg.failed}</Alert>
              }
              <Button onClick={() => handleSubmit()}>Submit</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <CicoReconcileComponent />
    </>
  )
}
