import React, { Suspense } from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import "@shared/assets/css/style.css";
import "@shared/assets/css/master-style.css";
import "@shared/assets/css/overide-style.css";

import { getElement } from "@shared/functions";
import { useSelector } from 'react-redux';
import { getAuth } from '@store/entities/auth';
import { LayoutRouter } from './layout.router';
import { withResizeDetector } from 'react-resize-detector';
import { routes } from '@shared/constants';
// import LoginPage from '@pages/login.pages';
// import UnauthorizedPage from '@pages/404.page';
import { lazy } from '@loadable/component';

const LoginPage = lazy(() => import('@pages/login.pages'));
const UnauthorizedPage = lazy(() => import('@pages/404.page'));
type Props = { width: number, children?: React.ReactNode };

const Main = ({ width, children }: Props) => {
  const { auth: { auth } } = useSelector(getAuth);
  return (
    <Suspense fallback={<div>Loading dialog box...</div>}>
      <Router>
        <LayoutRouter width={width} auth={auth}>
          <Routes>
            <Route path="/login-page" element={<LoginPage />} />
            {routes?.map((data: any, i: number) => (
              <Route
                key={i}
                path={data.path}
                element={getElement(data, auth)}
              />
            ))}
            <Route path="*" element={<UnauthorizedPage />} />
          </Routes>
        </LayoutRouter>
      </Router>
    </Suspense>
  )
}

export default withResizeDetector(Main);
