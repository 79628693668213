import { IFormProps } from "@shared/interface/form.interface";
import { Field } from "formik";

export const TextFieldInput = ({ field, errors }: { field: IFormProps, errors: any }) => {
  return (
    <>
      <Field
        type={field.type}
        name={field.name}
        className={`form-control ${errors.hasOwnProperty(field.name) && "errorField"
          }`}
        placeholder={field.placeholder}
        disabled={field.isActive}
      />
      {errors.hasOwnProperty(field.name) && (
        <div className="invalid-feedback errorFieldMessage">
          {errors[field.name]}
        </div>
      )}
    </>
  )
}
