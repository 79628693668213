import { ListRoleComponent } from "@components/module/user/role/list-role.component";
import UnauthorizedPage from "@pages/404.page";
import { Role } from "@shared/constants";
import { getAuthData } from "@store/entities/auth";
import { fetchAllRole } from "@store/entities/role";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const ListRolePage = () => {
  const { auth } = useSelector(getAuthData);
  const [perPage, setPerPage] = useState(30);
  const [page, setPage] = useState(1);
  const role = auth?.UserRole[0]?.name;
  let renderPage = <UnauthorizedPage />;
  const dispatch = useDispatch();

  useEffect(() => {
    const params = {
      limit: perPage,
      page: page,
    };
    dispatch(fetchAllRole(params));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage, page]);

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setPerPage(newPerPage);
    setPage(page);
  };

  if (auth) {
    switch (role) {
      case Role?.USER_SUPER_ADMIN:
        renderPage = (
          <ListRoleComponent
            auth={auth}
            handlePerRowsChange={handlePerRowsChange}
            handlePageChange={handlePageChange}
            perPage={perPage}
          />
        );
        break;
      default:
        break;
    }
  }
  return <>{renderPage}</>;
};

export default ListRolePage;
