import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const DatePickerField = ({ field: sourceField, errors }: any) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(sourceField);
  return (
    <>
      <DatePicker
        placeholderText={sourceField.placeholder}
        dateFormat="dd/MM/yyyy"
        className={`form-control`}
        selected={(field.value && new Date(field.value)) || null}
        onChange={(val: any) => {
          setFieldValue(field.name, val);
        }}
      />
      {errors.hasOwnProperty(field.name) && (
        <div className="invalid-feedback errorFieldMessage">
          {errors[field.name]}
        </div>
      )}
    </>
  );
};