
import React, { useEffect } from 'react'
import { Button } from '@components/common';
import { FieldForm } from '@components/forms';
import { LoginLayout } from '@components/layouts/login.layout';
import { RES_LOADING, RES_SUCCESS } from '@shared/constants';
import { loginForm } from '@shared/constants/form/auth.form.constant';
import { doLogin, getAuth } from '@store/entities/auth';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

const LoginPage = () => {
  const { auth } = useSelector(getAuth);
  const { auth: { access_token } } = auth;
  const dispatch = useDispatch();
  const handleSubmit = (e: any) => {
    const params = {
      user_name: e.email,
      password: e.password,
    }
    dispatch(doLogin(params));
  }

  useEffect(() => {
    if (auth.status === RES_SUCCESS && !auth.error) {
      window.location.href = "/";
    }
    else if (access_token) {
      window.location.href = "/";
    }
  }, [access_token, auth]);

  return (
    <LoginLayout>
      <p>Sign in to start your session</p>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={handleSubmit}
      >
        <Form>
          {loginForm?.map((form, i) => (
            <FieldForm
              disabled={auth?.status === RES_LOADING}
              key={i}
              type={form.type}
              name={form.name}
              placeholder={form.placeholder}
            />
          ))}

          <Button type={"submit"} mode={"secondary"} >Login</Button>
        </Form>
      </Formik>
    </LoginLayout>
  )
}

export default LoginPage;