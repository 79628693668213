import * as Yup from "yup";

export const userFormValidation = () => {
  return Yup.object().shape({
    email: Yup.string().required("Email is required").email("Format email is invalid"),
    role_id: Yup.string().required("Role is required"),
  });
}

export const userFormReqValidation = () => {
  return Yup.object().shape({
    email: Yup.string().required("Email is required").email("Format email is invalid"),
    password: Yup.string().required("Password is required"),
  })
}