/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from '@components/common';
import React, { useEffect, useMemo, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { convertDate } from '@shared/functions';
import { fetchMatchReconcile } from '@shared/api';
import { TableComponent } from '@components/tables/table.component';
import { columnMatchListReconcileSuccessCA, columnMatchReconcile } from '../data-tables/columns.reconcile';
import { SiMicrosoftexcel } from 'react-icons/si';

const CSV_FILE_PREFIX: string = "reconcile_match_success_";

export const MatchReconcileComponent = (props: any) => {
  const { isSuccess } = props;
  const [dateRange, setDateRange] = useState([null, null]);
  const [data, setData] = useState<any>();
  const [maxData, setMaxData] = useState<number>(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [isSubmit, setIsSubmit] = useState(false);
  const [startDate, endDate] = dateRange;
  const title = isSuccess ? "Match Reconcile List Success CA" : "Match Reconcile CA"

  useEffect(() => {
    setDateRange([null, null]);
    setData([]);
  }, [isSuccess]);

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setPerPage(newPerPage);
    setPage(page);
  };

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const fetchData = async () => {
    const body = {
      isSuccess: isSuccess,
      start_date: convertDate(startDate),
      end_date: convertDate(endDate),
      limit: perPage,
      page
    }
    const res = await fetchMatchReconcile(body);
    
    if (res.status === 200) {
      const newData = isSuccess ? res.data : [res.data.data];
      setData(newData === null ? [] : newData);
      setMaxData(res?.data?.total_row);
    }
    else {
      setData([]);
      setMaxData(0);
    }
  }

  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      fetchData();
      setIsSubmit(false);
    }
  }, [page, perPage, isSubmit]);

  const handleSubmit = async () => {
    fetchData();
  }

  const getMappedReconcileCSV = (reconcile: Array<any>) : string => {
    if (!reconcile || reconcile.length === 0)
      return "";

    const header = columnMatchListReconcileSuccessCA.map((column) => column.name) + "\n";
    const body = reconcile.map((e: Array<any>) => {
      return columnMatchListReconcileSuccessCA.map((column) => column.selector(e));
    }).join("\n");

    return "data:text/csv;charset=utf-8," + header + body;
  };

  const getReconcileCSV = async () : Promise<string> => {
    const res = await fetchMatchReconcile({
      isSuccess: isSuccess,
      start_date: convertDate(startDate),
      end_date: convertDate(endDate),
      limit: maxData,
      page: 1,
    });

    if (res.status === 200)
      return getMappedReconcileCSV(res?.data?.data);
    
    return "";
  };

  const contextActions = useMemo(() => {
    const handleDownloadCSV = async () => {
      const reconcileBlob = await getReconcileCSV();
      const url = encodeURI(reconcileBlob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${CSV_FILE_PREFIX}${convertDate(startDate)}-${convertDate(endDate)}.csv`);
      document.body.appendChild(link);
      link.click();
    };

    return (
      <>
        <Button mode="success" onClick={() => handleDownloadCSV()} disabled={!startDate || !endDate}>
          <SiMicrosoftexcel />
          <span className={"ml-2"}>Export</span>
        </Button>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxData]);

  return (
    <>
      <Row>
        <Col xs={12} sm={12}>
          <Card>
            <Card.Header>{title}</Card.Header>
            <Card.Body>
              <div className="form-group col-sm-3 p-0">
                <label className="col-form-label">Date Transaction</label>
                <DatePicker
                  className={`form-control`}
                  dateFormat="yyyy-MM-dd"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText={"Start Date - End Date"}
                  onChange={(update: any) => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                />
              </div>
              <Button onClick={() => handleSubmit()}>Search</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {isSuccess ?
        <TableComponent
          data={data?.data}
          columns={columnMatchListReconcileSuccessCA}
          title={title}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          paginationTotalRows={data?.total_row}
          actions={contextActions}
        />
        :
        <TableComponent
          data={data}
          columns={columnMatchReconcile}
          title={title}
        />
      }
    </>
  )
}
