import { Button, InputField } from '@components/common'
import { TableComponent } from '@components/tables/table.component'
import { downloadCicoCsv, fetchListCico } from '@shared/api'
import { REPORTING } from '@shared/constants'
import { initialValueCico } from '@shared/constants/dtos/initialValue.dtos'
import { formSearchCicoReporting } from '@shared/constants/form'
import { removeEmptyObject } from '@shared/functions'
import { IFormProps } from '@shared/interface/form.interface'
import { Form, Formik } from 'formik'
import React, { Key, useEffect, useMemo, useState } from 'react'
import { Alert, Card, Col, Row } from 'react-bootstrap'
import { SiMicrosoftexcel } from 'react-icons/si'

export const CicoComponent = (props: any) => {
  const { module, type, column } = props;
  const date = new Date();
  const todayDate = date.getDate() + "-" + date.getMonth() + "-" + date.getFullYear();
  const todayTime = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [data, setData] = useState<any>(null);
  const [listData, setListData] = useState<any>([]);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const fileNameCSV = () => `cico_reporting_${todayDate} ${todayTime}.csv`;

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  useEffect(() => {
    setData(null);
    setPerPage(10);
    setPage(1);
    setListData([]);
  }, [type, module]);

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setPerPage(newPerPage);
    setPage(page);
  };

  const fetchData = async () => {
    const body = {
      ...data,
      module,
      type,
      page,
      limit: perPage,
    }
    const newBody = removeEmptyObject(body);
    const res = await fetchListCico(newBody);
    if (res?.status === 200) {
      setListData(res?.data);
      setIsLoading(false);
      setErrMsg("");
    } else {
      setErrMsg(res?.data?.message || "Error");
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (data !== null) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage, page, data, isLoading]);

  const handleSubmit = async (e: any) => {
    setData(e);
    setIsLoading(true);
  };

  const contextActions = useMemo(() => {
    const handleDownloadCSV = async () => {
      const body = {
        ...data,
        type
      };
      const newBody = removeEmptyObject(body);
      const res = await downloadCicoCsv(newBody);
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileNameCSV());
      document.body.appendChild(link);
      link.click();
    };

    return (
      <>
        <Button mode="success" onClick={() => handleDownloadCSV()} disabled={listData?.data?.length > 0 ? false : true}>
          <SiMicrosoftexcel />
          <span className={"ml-2"}>Export</span>
        </Button>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listData, data]);

  return (
    <>
      <Row>
        <Col sm={12}>
          <Card>
            <Card.Header>
              <h3>{module === REPORTING ? "Reporting" : "Monitoring"} {type === "in" ? "Cash In" : "Cash Out"}</h3>
            </Card.Header>
            <Card.Body>
              <Formik
                enableReinitialize={true}
                initialValues={initialValueCico(data)}
                onSubmit={handleSubmit}
              >
                {({ errors }) => {
                  return (
                    <>
                      <Form>
                        <div className="form-row">
                          {formSearchCicoReporting.map((data: IFormProps, i: Key) => {
                            return (
                              <InputField
                                field={data}
                                errors={errors}
                                key={i}
                                width={6}
                              />
                            )
                          })}
                        </div>
                        {errMsg &&
                          <Alert className="mt-4" style={{ width: "fit-content" }} variant={"danger"} >{errMsg}</Alert>
                        }
                        <Button type="submit">{isLoading ? "Loading..." : "Search"}</Button>
                      </Form>
                    </>
                  )
                }}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <TableComponent
        columns={column}
        data={listData?.data || []}
        title={type === "in" ? "List Cash In" : "List Cash Out"}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        paginationTotalRows={listData?.total_row}
        actions={module === REPORTING ? contextActions : ""}
      />
    </>
  )
}