import { DetailCicoComponent } from '@components/module/reconcile/cico/detail-cico.component';
import UnauthorizedPage from '@pages/404.page';
import { Role } from '@shared/constants';
import { getAuthData } from '@store/entities/auth';
import { fetchReconcileCicoHeader } from '@store/entities/reconcile';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

const DetailCicoPage = () => {
  const { auth } = useSelector(getAuthData);
  const role = auth?.UserRole[0]?.name;
  let renderPage = <UnauthorizedPage />;
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchReconcileCicoHeader({ recon_id: id }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (auth) {
    switch (role) {
      case Role?.USER_SUPER_ADMIN:
        renderPage = <DetailCicoComponent auth={auth} reconId={id} />
        break;
      default:
        break;
    }
  }
  return <>{renderPage}</>
}

export default DetailCicoPage