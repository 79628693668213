import { Button, InputField, ModalDetailTableCard } from '@components/common'
import { SelectFieldComponent } from '@components/common/input/select.input'
import { fetchTransaction } from '@shared/api'
import { CA, MONITORING, REPORTING, Transaction } from '@shared/constants'
import { initialValueFilterMonitoring } from '@shared/constants/dtos/initialValue.dtos'
import { formSearchMonitoring, formStatus, optionStatePayment, transactionDate } from '@shared/constants/form/monitoring.form.constant'
import { convertDate, mappingDefaultStatusFromState, mappingFormStatus, removeEmptyObject } from '@shared/functions'
import { IFormProps } from '@shared/interface/form.interface'
import { searchFormValidations } from '@shared/validations/search-form.validation'
import { Form, Formik } from 'formik'
import React, { Key, useEffect, useState } from 'react'
import { Alert, Card, Col, Row } from 'react-bootstrap'
import { DataTableMonitoringComponent } from '../../data-tables/data-table.component'

export const PaymentComponent = (props: any) => {
  const { typeOrg, module } = props;
  const title = (module === MONITORING ? "Monitoring" : "Reporting") + " " + (typeOrg === CA ? "CA" : "Partner");
  const [formData, setFormData] = useState(formSearchMonitoring);
  const [dataList, setDataList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [fieldState, setFieldState] = useState<string>("");
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [paramSearch, setParamSearch] = useState<any>(null);
  const [isSubmit, setIsSubmit] = useState<any>(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [detailModal, setDetailModal] = useState<any>({});

  let initialState: any = {
    status: mappingDefaultStatusFromState(fieldState),
    state: fieldState,
  }

  useEffect(() => {
    if (module === MONITORING) {
      setFormData([...formSearchMonitoring, optionStatePayment]);
    } else {
      setFormData([...formSearchMonitoring, optionStatePayment, transactionDate]);
    }
  }, [module]);

  useEffect(() => {
    setParamSearch(null);
    setPerPage(10);
    setPage(1);
    setDataList([]);
  }, [module, typeOrg]);

  useEffect(() => {
    setParamSearch({ ...paramSearch, ...initialState });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldState]);

  useEffect(() => {
    if (isSubmit === true) {
      fetchPayment(paramSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage, page, paramSearch, isSubmit]);

  const fetchPayment = async (temp: any) => {
    let body = {
      ...temp,
      page,
      limit: perPage,
      module: module,
      type: typeOrg,
      moduleType: Transaction?.PAYMENT,
    }
    if (module === REPORTING) body = { ...body, transaction_date: convertDate(temp.transaction_date), }
    const data = await fetchTransaction(body);
    if (data?.status === 200) {
      setDataList(data?.data || []);
      setIsLoading(false);
      setErrMsg("");
    } else {
      setErrMsg(data?.data?.message || "Error");
      setIsLoading(false);
    }
    setIsSubmit(!isSubmit)
  }

  const handleSubmit = async (e: any) => {
    setIsLoading(true);
    const temp = removeEmptyObject(e);
    if (Object.keys(temp).length === 0) {
      setErrMsg("Please filled at least 1 field");
      setIsLoading(false);
    }
    else if (!temp.status) {
      setErrMsg("Please filled status field");
      setIsLoading(false);
    }
    else {
      setParamSearch(temp);
      setIsSubmit(!isSubmit);
    }
  }

  const handlePageChange = (page: any) => {
    setPage(page);
    setIsSubmit(!isSubmit);
  };

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setPerPage(newPerPage);
    setPage(page);
    setIsSubmit(!isSubmit);
  };

  const handleShowDetail = (props: any) => {
    const { row, type } = props;
    setShowDetailModal(!showDetailModal);
    const obj = JSON.parse(row);
    setDetailModal({ ...obj, type });
  }

  return (
    <>
      <Row>
        <Col sm={12}>
          <Card>
            <Card.Header>
              <h3 style={{ color: typeOrg === CA ? "#90e8fb" : "#FF009E" }}>Payment {title}</h3>
            </Card.Header>
            <Card.Body>
              <Formik
                enableReinitialize={true}
                validationSchema={searchFormValidations(module)}
                initialValues={initialValueFilterMonitoring(paramSearch)}
                onSubmit={handleSubmit}
              >
                {({ values, errors }) => {
                  setTimeout(() => setFieldState(values.state), 0);
                  return (
                    <>
                      <Form>
                        <div className="form-row">
                          {formData.map((data: IFormProps, i: Key) => {
                            return (
                              <InputField
                                field={data}
                                errors={errors}
                                key={i}
                                width={6}
                              />
                            )
                          })}
                          <div className={`form-group col-sm-6`}>
                            <label className="col-form-label">Status</label>
                            <div className="col p-0">
                              <SelectFieldComponent field={mappingFormStatus(values, formStatus)} errors={errors} />
                            </div>
                            {errMsg &&
                              <Alert className="mt-4" style={{ width: "fit-content" }} variant={"danger"} >{errMsg}</Alert>
                            }
                          </div>
                        </div>
                        {/* <Button>{isLoading ? "Loading..." : "Search"}</Button> */}
                        <Button mode={typeOrg === CA ? "primary" : "tertiary"}>{isLoading ? "Loading..." : "Search"}</Button>
                      </Form>
                    </>
                  )
                }}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <DataTableMonitoringComponent
        title={`List Payment ${title}`}
        data={dataList?.data || []}
        type={typeOrg}
        loading={isLoading}
        paginationTotalRows={dataList?.total_row}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paramSearch={paramSearch}
        moduleType={Transaction.PAYMENT}
        module={module}
        handleOpenModal={(row: any, type: string) => handleShowDetail({ row, type })}
      />

      <ModalDetailTableCard
        show={showDetailModal}
        onHide={() => setShowDetailModal(!showDetailModal)}
        objDetail={detailModal}
        title={`${detailModal.type} From Biller`}
      />
    </>
  )
}
