import { createAsyncThunk, createSlice, createSelector } from "@reduxjs/toolkit";
import { api } from "@shared/api";
import { api_url, RES_IDLE, RES_STATUS } from "@shared/constants";
import { assemblyParameters, setPending, setRejected } from "@shared/functions";


export const fetchReconcileCicoHeader = createAsyncThunk(
  "user/FETCH_CICO_HEADER",
  async (params: any, { rejectWithValue }) => {
    try {
      const paramQuery = assemblyParameters(params);
      const res = await api.get(`${api_url}/v1/cico/reconciliations?${paramQuery}`);
      return res.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.response.data.message });
    }
  }
)

export const fetchReconcileCicoDetail = createAsyncThunk(
  "user/FETCH_CICO_DETAIL",
  async (params: any, { rejectWithValue }) => {
    try {
      const { type, ...rest } = params;
      const paramQuery = assemblyParameters(rest);
      const res = await api.get(`${api_url}/v1/${type}/reconciliations?${paramQuery}`);
      return res.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.response.data.message });
    }
  }
)

const slice = createSlice({
  name: "reconcile",
  initialState: {
    listCicoHeader: [],
    listCicoDetail: [],
    cicoHeader: {},
    status: "idle",
    error: "",
  },
  reducers: {
    clearStatus: (state: any) => {
      state.status = RES_IDLE;
      state.error = "";
    },
    setCicoHeaderDetai: (state: any, payload: any) => {
      state.cicoHeader = payload;
      state.status = RES_STATUS.SUCCEEDED;
      state.error = "";
    }
  },
  extraReducers: (builder) => {
    //FETCH_CICO_HEADEAR
    builder.addCase(fetchReconcileCicoHeader.pending, (state) => setPending(state));
    builder.addCase(fetchReconcileCicoHeader.fulfilled, (state, { payload }: any) => setCicoHeaderList(state, payload));
    builder.addCase(fetchReconcileCicoHeader.rejected, (state, action) => setRejected(state, action));
    //FETCH_CICO_DETAIL
    builder.addCase(fetchReconcileCicoDetail.pending, (state) => setPending(state));
    builder.addCase(fetchReconcileCicoDetail.fulfilled, (state, { payload }: any) => setCicoDetailList(state, payload));
    builder.addCase(fetchReconcileCicoDetail.rejected, (state, action) => setRejected(state, action));
  }
})

export default slice.reducer;

const setCicoHeaderList = (state: any, payload: any) => {
  state.listCicoHeader = payload;
  state.status = RES_STATUS.SUCCEEDED;
  state.error = "";
};

const setCicoDetailList = (state: any, payload: any) => {
  state.listCicoDetail = payload;
  state.status = RES_STATUS.SUCCEEDED;
  state.error = "";
};

export const getReconcile = createSelector(
  (state: any) => ({ reconcile: state.entities.reconcile }),
  state => state
)