import DetailCicoPage from "@pages/reconcile/cico/cico-detail.page";
import CicoReconcilePage from "@pages/reconcile/cico/cico.page";
import ListReconcilePage from "@pages/reconcile/payment-point/list/list-reconcile.page";
import MatchReconcilePage from "@pages/reconcile/payment-point/match/match-reconcile.page";
import PurchasePage from "@pages/reconcile/purchase/purchase.page";
import SchedulerReconcilePage from "@pages/reconcile/scheduler-reconcile.page";

export const reconcileRoute = [
  {
    path: "/reconcile/list",
    element: <ListReconcilePage />,
    isAuth: true,
  },
  {
    path: "/reconcile/list/success",
    element: <ListReconcilePage />,
    isAuth: true,
  },
  {
    path: "/reconcile/match",
    element: <MatchReconcilePage />,
    isAuth: true,
  },
  {
    path: "/reconcile/match/success",
    element: <MatchReconcilePage />,
    isAuth: true,
  },
  {
    path: "/reconcile/scheduler",
    element: <SchedulerReconcilePage />,
    isAuth: true,
  },
  {
    path: "/reconcile/cico",
    element: <CicoReconcilePage />,
    isAuth: true,
  },
  {
    path: "/reconcile/cico/:id",
    element: <DetailCicoPage />,
    isAuth: true,
  },
  {
    path: "/reconcile/purchase",
    element: <PurchasePage />,
    isAuth: true,
  }
]