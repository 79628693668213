import { Field } from "formik";

export const SelectFieldComponent = ({ field, errors }: any) => {
  return (
    <>
      <Field
        className={`form-control ${errors.hasOwnProperty(field.name) && "errorField"}`}
        name={field.name}
        placeholder={field.placeholder}
        as="select"
        multiple={false}
      >
        <option value="">{field.placeholder}</option>
        {field?.options?.map((option: any) => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </Field>
      {errors.hasOwnProperty(field.name) && (
        <div className="invalid-feedback errorFieldMessage">
          {errors[field.name]}
        </div>
      )}
    </>
  );
};