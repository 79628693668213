import React from 'react'
import DatePicker from "react-datepicker";

interface IDatePickerRangeDate {
  startDate: any;
  endDate: any;
  setDateRange: any;
}

export const DatePickerRangeDate = (props: IDatePickerRangeDate) => {
  const { startDate, endDate, setDateRange } = props;
  return (
    <DatePicker
      className={`form-control`}
      dateFormat="yyyy-MM-dd"
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      placeholderText={"Start Date - End Date"}
      onChange={(update: any) => {
        setDateRange(update);
      }}
      isClearable={true}
    />
  )
}
