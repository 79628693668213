import React, { useCallback } from 'react'

interface ISearchInputComponent {
  onFilter: any;
  data: any;
  placeholder?: string;
  paramFilter: string;
}
export const SearchInputComponent = (props: ISearchInputComponent) => {
  const { onFilter, data, placeholder, paramFilter } = props;

  const handleFind = useCallback((val: any) => {
    const filter = data?.filter((el: any) => el[`${paramFilter}`]?.toLowerCase().includes(val?.toLowerCase()));
    onFilter(filter);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, onFilter]);

  return (
    <div className="form-group m-0 p-0">
      <input className="form-control" placeholder={placeholder} onChange={(e: any) => handleFind(e.target.value)} />
    </div>
  )
}
