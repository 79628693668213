import CicoReportingPage from "@pages/reporting/cico/cico.page";
import CommitReportingPage from "@pages/reporting/commit/commit.page";
import ReportingDepositPage from "@pages/reporting/deposit/deposit.page";
import InquiryReportingPage from "@pages/reporting/inquiry/inquiry.page";
import PaymentReportingPage from "@pages/reporting/payment/payment.page";
import PurchaseReportingPage from "@pages/reporting/purchase/purchase.page";
import ReportingWithdrawalPage from "@pages/reporting/withdrawal/withdrawal.page";

export const reportingRoute = [
  {
    path: "/reporting/inquiry/*",
    element: <InquiryReportingPage />,
    isAuth: true,
  },
  {
    path: "/reporting/payment/*",
    element: <PaymentReportingPage />,
    isAuth: true,
  },
  {
    path: "/reporting/commit/*",
    element: <CommitReportingPage />,
    isAuth: true,
  },
  {
    path: "/reporting/purchase/*",
    element: <PurchaseReportingPage />,
    isAuth: true,
  },
  {
    path: "/reporting/cash/in",
    element: <CicoReportingPage type={"in"} />,
    isAuth: true,
  },
  {
    path: "/reporting/cash/out",
    element: <CicoReportingPage type={"out"} />,
    isAuth: true,
  },
  {
    path: "/reporting/deposit",
    element: <ReportingDepositPage />,
    isAuth: true,
  },
  {
    path: "/reporting/withdrawal",
    element: <ReportingWithdrawalPage />,
    isAuth: true,
  },
];
