/* eslint-disable react-hooks/exhaustive-deps */
import { Button, SearchInputComponent } from "@components/common";
import { fetchListReconcile } from "@shared/api";
import { convertDate } from "@shared/functions";
import React, { useEffect, useState } from "react";
import { Alert, Card, Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  columnListReconcile,
  columnsListReconcileSuccess,
} from "../data-tables/columns.reconcile";
import { TableComponent } from "../../../tables/table.component";

export const ListReconcileComponent = (props: any) => {
  const { isSuccess } = props;
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [data, setData] = useState<any>();
  const [errMsg, setErrMsg] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [isSubmit, setIsSubmit] = useState(false);
  const [listData, setListData] = useState<any>();
  const title = isSuccess ? "List Reconcile Success CA" : "List Reconcile CA";
  const columns = isSuccess ? columnsListReconcileSuccess : columnListReconcile;

  useEffect(() => {
    setDateRange([null, null]);
    setData([]);
  }, [isSuccess]);

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setPerPage(newPerPage);
    setPage(page);
  };

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const fetchData = async () => {
    const body = {
      isSuccess: isSuccess,
      start_date: convertDate(startDate),
      end_date: convertDate(endDate),
      limit: perPage,
      page,
    };
    const res = await fetchListReconcile(body);
    if (res.status === 200) {
      setData(res?.data);
      setListData(res?.data?.data);
      setErrMsg("");
    } else {
      setData([]);
      setErrMsg(res?.data?.message || "Error");
    }
  };

  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      fetchData();
      setIsSubmit(false);
    }
  }, [page, perPage, isSubmit]);

  const handleSubmit = async () => {
    if (startDate !== null && endDate !== null) {
      setIsSubmit(true);
    } else {
      setErrMsg("Please filled Date Transaction field");
    }
  };

  const actionTable = (
    <Row className="align-items-center w-50">
      {isSuccess && (
        <SearchInputComponent
          data={data?.data}
          onFilter={(data: any) => setListData(data)}
          placeholder="Search Customer ID"
          paramFilter={"customer_id"}
        />
      )}
    </Row>
  );

  return (
    <>
      <Row>
        <Col xs={12} sm={12}>
          <Card>
            <Card.Header>{title}</Card.Header>
            <Card.Body>
              <Row className="mb-3">
                <Col xs={12} md={3}>
                  <label>Date Transaction</label>
                  <DatePicker
                    className={`form-control`}
                    dateFormat="yyyy-MM-dd"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText={"Start Date - End Date"}
                    onChange={(update: any) => {
                      setDateRange(update);
                    }}
                    isClearable={true}
                  />
                </Col>
              </Row>
              {errMsg && (
                <Alert
                  className="mt-4"
                  style={{ width: "fit-content" }}
                  variant={"danger"}
                >
                  {errMsg}
                </Alert>
              )}
              <Button onClick={() => handleSubmit()}>Search {}</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <TableComponent
        data={listData || []}
        columns={columns}
        title={title}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        paginationTotalRows={data?.total_row}
        actions={actionTable}
      />
    </>
  );
};
