import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";
import { api } from "@shared/api";
import { api_url, RES_STATUS } from "@shared/constants";
import { assemblyParameters, setPending, setRejected } from "@shared/functions";

export const fetchAllRole = createAsyncThunk(
  "role/FETCH_ALL",
  async (body: any, { rejectWithValue }) => {
    try {
      const params = assemblyParameters(body);
      const res = await api.get(
        `${api_url}/v1/roles${params ? `?${params}` : ""}`
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.response.data.message });
    }
  }
);

export const fetchRoleById = createAsyncThunk(
  "role/FETCH_BY_ID",
  async (id: any, { rejectWithValue }) => {
    try {
      const res = await api.get(`${api_url}/v1/roles/${id}`);
      return res.data.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.response.data.message });
    }
  }
);

const slice = createSlice({
  name: "role",
  initialState: {
    roleList: [],
    roleDetail: {},
    length: 0,
    status: "idle",
    error: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    //FETCH_ALL
    builder.addCase(fetchAllRole.pending, (state) => setPending(state));
    builder.addCase(fetchAllRole.fulfilled, (state, { payload }: any) =>
      setRoleList(state, payload)
    );
    builder.addCase(fetchAllRole.rejected, (state, action) =>
      setRejected(state, action)
    );
    //FETCH_BY_ID
    builder.addCase(fetchRoleById.pending, (state) => setPending(state));
    builder.addCase(fetchRoleById.fulfilled, (state, { payload }: any) =>
      setRoleDetail(state, payload)
    );
    builder.addCase(fetchRoleById.rejected, (state, action) =>
      setRejected(state, action)
    );
  },
});

export default slice.reducer;

export const getRoleList = createSelector(
  (state: any) => ({ roleList: state.entities.role.roleList }),
  (state) => state
);

export const getRole = createSelector(
  (state: any) => ({ role: state.entities.role }),
  (state) => state
);

const setRoleList = (state: any, payload: any) => {
  state.roleList = payload?.data;
  state.status = RES_STATUS.SUCCEEDED;
  state.error = "";
  state.length = payload?.length;
};

const setRoleDetail = (state: any, payload: any) => {
  state.roleDetail = payload;
  state.status = RES_STATUS.SUCCEEDED;
  state.error = "";
};
