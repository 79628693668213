import { Field } from "formik";
import { Key } from "react";

export const CheckboxFieldComponent = ({ field }: { field: any }) => {
  const renderLabel = (option: any) => (
    <>
      <Field
        type={field.type}
        name={field.name}
        value={option.value}
        id={option.value}
      />
      <label className="form-check-label" htmlFor={option.value}>
        {option.label}
      </label>
    </>
  );
  return (
    <>
      {field?.options?.map((option: any, i: Key) => {
        const fieldOption =
          field.optionType === "list" ? (
            <div className="radio" key={i}>
              <label>{renderLabel(option)}</label>
            </div>
          ) : (
            <label className="radio-inline mr-3" key={i}>
              {renderLabel(option)}
            </label>
          );
        return fieldOption;
      })}
    </>
  );
};