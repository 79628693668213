// import { day_names, time_lesson_schedules } from "@shared/constants";

export const getToday = () => {
  let d = new Date(),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join("-");
};

export const diffDate = (date_start: any, date_end: any) => {
  const toDay = 24 * 3600 * 1000;
  return Math.floor((date_end - date_start) / toDay + 1);
};

// export const getTodayDateName = (date: any) => {
//   const today = day_names.find((el: any) => el.id === date.getDay());
//   return { day_name: today?.label, date: date.getDate() }
// }

// export const getTimeLabel = (params: any) => {
//   const findTime = time_lesson_schedules.find((el: any) => el.value === params);
//   return findTime?.label;
// }

export const convertDate = (date: any) => {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return ([year, month, day].join("-"));
};


export const getDate = (date: any) => {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return ([day, month, year].join(""));
};