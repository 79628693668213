import { AiFillReconciliation } from "react-icons/ai";
import { FiClipboard, FiHome, FiMonitor, FiUsers } from "react-icons/fi";
import { IMenu } from "../menu.constant";

export const navSuperAdmin: IMenu[] = [
  {
    url: "/",
    title: "Home",
    module: "all",
    icon: <FiHome />,
    permission: "",
  },
  {
    url: "/monitoring",
    title: "Monitoring",
    module: "all",
    icon: <FiMonitor />,
    subMenus: [
      {
        url: "/monitoring/payment-point",
        title: "Payment Point",
        module: "all",
        subMenus: [
          {
            url: "/monitoring/ca",
            title: "Collection Agents",
            module: "all",
            subMenus: [
              {
                url: "/monitoring/inquiry?ca",
                title: "Inquiry",
                module: "all",
                permission: "pp.transaction-monitoring.inquiry.ca",
              },
              {
                url: "/monitoring/payment?ca",
                title: "Payment",
                module: "all",
                permission: "pp.transaction-monitoring.payment.ca",
              },
              {
                url: "/monitoring/commit?ca",
                title: "Commit",
                module: "all",
                permission: "pp.transaction-monitoring.commit.ca",
              },
            ],
          },
          {
            url: "/monitoring/partner",
            title: "Partner",
            module: "all",
            subMenus: [
              {
                url: "/monitoring/inquiry?partner",
                title: "Inquiry",
                module: "all",
                permission: "pp.transaction-monitoring.inquiry.partner",
              },
              {
                url: "/monitoring/payment?partner",
                title: "Payment",
                module: "all",
                permission: "pp.transaction-monitoring.payment.partner",
              },
            ],
          },
        ],
      },
      {
        url: "/monitoring/purchase",
        title: "Purchase",
        module: "all",
        permission: "cico.transaction-monitoring.cash.in",
      },
      {
        url: "/monitoring/cash/in/virgo",
        title: "Customer Top Up Virgo",
        module: "all",
        permission: "cico.transaction-monitoring.cash.in",
      },
      {
        url: "/monitoring/cash/in",
        title: "Cash In",
        module: "all",
        permission: "cico.transaction-monitoring.cash.in",
      },
      {
        url: "/monitoring/cash/out",
        title: "Cash Out",
        module: "all",
        permission: "cico.transaction-monitoring.cash.out",
      },
      {
        url: "/monitoring/deposit",
        title: "Deposit",
        module: "all",
        permission: "dw.transaction-monitoring.deposit",
      },
      {
        url: "/monitoring/withdrawal",
        title: "Withdrawal",
        module: "all",
        permission: "dw.transaction-monitoring.withdrawal",
      },
    ],
  },
  {
    url: "/reporting",
    title: "Reporting",
    module: "all",
    icon: <FiClipboard />,
    subMenus: [
      {
        url: "/reporting/payment-point",
        title: "Payment Point",
        module: "all",
        subMenus: [
          {
            url: "/reporting/ca",
            title: "Collection Agents",
            module: "all",
            subMenus: [
              {
                url: "/reporting/inquiry?ca",
                title: "Inquiry",
                module: "all",
                permission: "pp.transaction-reporting.inquiry.ca",
              },
              {
                url: "/reporting/payment?ca",
                title: "Payment",
                module: "all",
                permission: "pp.transaction-reporting.payment.ca",
              },
              {
                url: "/reporting/commit?ca",
                title: "Commit",
                module: "all",
                permission: "pp.transaction-reporting.commit.ca",
              },
            ],
          },
          {
            url: "/reporting/partner",
            title: "Partner",
            module: "all",
            subMenus: [
              {
                url: "/reporting/inquiry?partner",
                title: "Inquiry",
                module: "all",
                permission: "pp.transaction-reporting.inquiry.partner",
              },
              {
                url: "/reporting/payment?partner",
                title: "Payment",
                module: "all",
                permission: "pp.transaction-reporting.payment.partner",
              },
            ],
          },
        ],
      },
      {
        url: "/reporting/purchase",
        title: "Purchase",
        module: "all",
        permission: "cico.transaction-monitoring.cash.in",
      },
      {
        url: "/reporting/cash/in",
        title: "Cash In",
        module: "all",
        permission: "pp.transaction-reporting.cash-in",
      },
      {
        url: "/reporting/cash/out",
        title: "Cash Out",
        module: "all",
        permission: "pp.transaction-reporting.cash-out",
      },
      {
        url: "/reporting/deposit",
        title: "Deposit",
        module: "all",
        permission: "dw.transaction-reporting.deposit",
      },
      {
        url: "/reporting/withdrawal",
        title: "Withdrawal",
        module: "all",
        permission: "dw.transaction-reporting.withdrawal",
      },
    ],
  },
  {
    url: "/reconcile",
    title: "Reconcile",
    module: "all",
    icon: <AiFillReconciliation />,
    subMenus: [
      {
        url: "/reconcile/scheduler",
        title: "Scheduler",
        module: "all",
        permission: "settings.manage-user.update-block-status",
      },
      {
        url: "/reconcile/list",
        title: "List CA",
        module: "all",
        permission: "pp.transaction-reconcile.list",
      },
      {
        url: "/reconcile/list?success",
        title: "List Success CA",
        module: "all",
        permission: "pp.transaction-reconcile.list-success",
      },
      {
        url: "/reconcile/match",
        title: "Match CA",
        module: "all",
        permission: "pp.transaction-reconcile.match",
      },
      {
        url: "/reconcile/match?success",
        title: "Match List Success CA",
        module: "all",
        permission: "pp.transaction-reconcile.match.list-success",
      },
      {
        url: "/reconcile/cico",
        title: "CI/CO",
        module: "all",
        permission: "pp.transaction-reconcile.cico-header",
      },
      {
        url: "/reconcile/purchase",
        title: "Purchase",
        module: "all",
        permission: "pp.transaction-reconcile.cico-header",
      },
    ],
  },
  {
    url: "/user",
    title: "User",
    module: "all",
    icon: <FiUsers />,
    subMenus: [
      {
        url: "/user/view",
        title: "User",
        module: "all",
        permission: "settings.manage-user.list",
      },
      {
        url: "/user/role",
        title: "Role",
        module: "all",
        permission: "settings.manage-role.list",
      },
      // {
      //   url: "/user/permission",
      //   title: "Permission",
      //   module: "all",
      //   permission: "settings.manage-permission.list",
      // },
      {
        url: "/user/user-request",
        title: "Request",
        module: "all",
        permission: "settings.manage-user.update-block-status",
      },
      {
        url: "/user/user-request/list",
        title: "List Request",
        module: "all",
        permission: "settings.manage-user.activities",
      },
    ],
  },
];
