const Footer = () => {
  return (
    <div className="footer">
      <div className="copyright">
        <p>
        © 2022 PT. Algo Gapura Informasi.
        </p>
      </div>
    </div>
  );
};

export default Footer;
