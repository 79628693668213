import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import Table from 'react-data-table-component';
import { columnHeaderCico } from '../../cico/data-tables/columns.cico';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReconcileCicoHeader, getReconcile } from '@store/entities/reconcile';
import { convertDate } from '@shared/functions';
import { DatePickerRangeDate } from '@components/common';

export const CicoHeaderComponent = (props: any) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const { reconcile } = useSelector(getReconcile);
  const { listCicoHeader } = reconcile;
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    const obj = {
      start_date: convertDate(startDate),
      end_date: convertDate(endDate),
      limit: perPage,
      page: page,
    }
    if (startDate && endDate) {
      dispatch(fetchReconcileCicoHeader(obj));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, page, perPage]);

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setPerPage(newPerPage);
    setPage(page);
  };

  const FilterForm = () => {
    return (
      <Col xl={3}>
        <DatePickerRangeDate startDate={startDate} endDate={endDate} setDateRange={setDateRange} />
      </Col>
    );
  }

  return (
    <>
      <Row>
        <Col xs={12} sm={12}>
          <Card>
            <Card.Header>CI/CO Header
              <FilterForm />
            </Card.Header>
            <Card.Body>
              <Table
                columns={columnHeaderCico}
                data={listCicoHeader?.data || []}
                pagination
                paginationServer
                paginationTotalRows={listCicoHeader?.total_row}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}
