export const formCashInVirgo: any = [
  {
    name: "transaction_id",
    type: "number",
    label: "Transaction Id",
    placeholder: "e.g 12321313",
  },
  {
    name: "customer_number",
    type: "text",
    label: "Customer Number",
    placeholder: "e.g 12314313",
  },
  {
    name: "algo_state",
    type: "select",
    label: "Algo State",
    placeholder: "Choose State",
    options: [
      { label: "Validation", value: "VALIDATION" },
      { label: "Committed", value: "COMMITTED" },
      { label: "Transaction", value: "TRANSACTION" }
    ]
  },
]