import { PurchaseComponent } from '@components/module/purchase/purchase.component';
import UnauthorizedPage from '@pages/404.page';
import { MONITORING, Role } from '@shared/constants';
import { capitalizeFirstLetter } from '@shared/functions';
import { getAuthData } from '@store/entities/auth';
import React from 'react'
import { useSelector } from 'react-redux';

const PurchaseMonitoringPage = () => {
  const { auth } = useSelector(getAuthData);
  const role = auth?.UserRole[0]?.name;
  let renderPage = <UnauthorizedPage />


  if (auth) {
    switch (role) {
      case Role?.USER_SUPER_ADMIN:
        renderPage = <PurchaseComponent type={capitalizeFirstLetter(MONITORING)} />
        break;
      default:
        break;
    }
  }
  return <>{renderPage}</>
}

export default PurchaseMonitoringPage