import { Button, ModalFormComponent } from '@components/common'
import { initialValueChangePassword } from '@components/module/user/dtos/user.dto'
import { putChangePassword } from '@shared/api'
import { formChangePassword } from '@shared/constants/form/user.form.constant'
import { changePasswordValidation } from '@shared/validations/search-form.validation'
import { getAuthData } from '@store/entities/auth'
import React, { useMemo, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'

const ProfilePage = () => {
  const { auth } = useSelector(getAuthData);
  const [showModal, setShowModal] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const tableContent = useMemo(() => (
    [
      {
        label: "Username",
        value: auth.Username
      },
      {
        label: "Role",
        value: auth?.UserRole[0].name
      },
    ]
  ), [auth]);

  const handleSubmit = async (e: any) => {
    if (e.new_password === e.confirm_new_password) {
      const data = await putChangePassword(e);
      if (data?.status === 200) {
        setShowModal(!showModal);
        setErrMsg("");
      } else {
        setErrMsg(data?.data?.message || "Error");
      }
    }
  }
  
  return (
    <>
      <Row>
        <Col xs={12}>
          <Card>
            <Card.Header>
              <h3 className="mb-3">Profile</h3>
            </Card.Header>
            <Card.Body>
              <table className="table table-hover">
                <tbody>
                  {tableContent.map((data: any, i: number) => (
                    <tr key={i}>
                      <td>{data.label}</td>
                      <td>{data.value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Card.Body>
            <Card.Footer>
              <Button onClick={() => setShowModal(!showModal)}>Change Password</Button>
            </Card.Footer>
          </Card>
        </Col>
      </Row>

      <ModalFormComponent
        show={showModal}
        onHide={() => setShowModal(!showModal)}
        title={`Change Password`}
        onSubmit={(e: any) => handleSubmit(e)}
        initialValue={initialValueChangePassword(null)}
        validationSchema={changePasswordValidation}
        formData={formChangePassword}
        alertMessage={errMsg}
      />
    </>
  )
}

export default ProfilePage