import { IFormProps } from "@shared/interface/form.interface";
import { Field } from "formik";
import { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

export const PasswordFieldInput = ({ field, errors }: { field: IFormProps, errors: any }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <div className="d-flex align-items-center">
        <Field
          type={show ? "text" : "password"}
          name={field.name}
          placeholder={field.placeholder}
          className={`form-control ${errors.hasOwnProperty(field.name) && "errorField"}`}
          disabled={field.isActive}
        />
        {show ?
          <FaEyeSlash onClick={() => setShow(!show)} style={{ marginLeft: "-3ch" }} />
          :
          <FaEye onClick={() => setShow(!show)} style={{ marginLeft: "-3ch" }} />
        }
      </div>
      {errors.hasOwnProperty(field.name) && (
        <div className="invalid-feedback errorFieldMessage">
          {errors[field.name]}
        </div>
      )}
    </>
  )
}