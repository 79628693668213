import CreateRolePage from '@pages/user/role/create-role.page'
import PermissionPage from '@pages/user/permission.page'
import UserDetailPage from '@pages/user/user-detail.page'
import UserListPage from '@pages/user/user-list.page'
import ListUserRequestPage from '@pages/user/user-request-list.page'
import UserRequestPage from '@pages/user/user-request.page'
import React from 'react'
import ListRolePage from '@pages/user/role/list-role.page'
import DetailRolePage from '@pages/user/role/detail-role.page'

export const userRoute = [
  {
    path: "/user/view",
    element: <UserListPage />,
    isAuth: true
  },
  {
    path: "/user/role",
    element: <ListRolePage />,
    isAuth: true
  },
  {
    path: "/user/role/create",
    element: <CreateRolePage />,
    isAuth: true
  },
  {
    path: "/user/role/detail/:id",
    element: <DetailRolePage />,
    isAuth: true
  },
  {
    path: "/user/permission",
    element: <PermissionPage />,
    isAuth: true
  },
  {
    path: "/user/user-request",
    element: <UserRequestPage />,
    isAuth: true
  },
  {
    path: "/user/user-request/list",
    element: <ListUserRequestPage />,
    isAuth: true
  },
  {
    path: "/user/detail/:id",
    element: <UserDetailPage />,
    isAuth: true
  }
]