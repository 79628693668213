import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { api_url, RES_IDLE, RES_STATUS } from "@shared/constants";
import { api } from "@shared/api";

export const doLogin = createAsyncThunk(
  "auth/login",
  async (formData: any, { rejectWithValue }) => {
    try {
      const res = await api.post(`${api_url}/v1/users/login`, formData);
      const { data: { IDToken }, data } = res.data;
      localStorage.setItem("AID-T", btoa(JSON.stringify(IDToken)));
      localStorage.setItem("AID-D", btoa(JSON.stringify(data)));
      return res.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.response.data.message });
    }
  }
);

const tokenLocal = localStorage.getItem("AID-T");
const userLocal = localStorage.getItem("AID-D");

const slice = createSlice({
  name: "auth",
  initialState: {
    auth: {
      access_token: tokenLocal ? JSON.parse(atob(tokenLocal)) : "",
      data: userLocal ? JSON.parse(atob(userLocal)) : "",
    },
    status: "idle",
    error: "",
  },
  reducers: {
    fetchLogout: (state: any) => {
      localStorage.removeItem("AID-T");
      localStorage.removeItem("AID-D");
      state.auth = {};
      state.status = RES_IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(doLogin.pending, (state) => {
      state.auth = { ...state.auth };
      state.status = RES_STATUS.LOADING;
    });
    builder.addCase(doLogin.fulfilled, (state, { payload }: any) => {
      state.auth = payload;
      state.status = RES_STATUS.SUCCEEDED;
      state.error = "";
    });
    builder.addCase(doLogin.rejected, (state, { payload }: any) => {
      state.status = RES_STATUS.FAILED;
      state.error = payload?.error || "";
    });
  },
});

export const { fetchLogout } = slice.actions;
export default slice.reducer;

export const getAuthData = createSelector(
  (state: any) => ({ auth: state.entities.auth.auth.data }),
  (state) => state
);

export const getAuth = createSelector(
  (state: any) => ({ auth: state.entities.auth }),
  (state) => state
);
