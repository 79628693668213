import { CicoHeaderComponent } from '@components/module/reconcile/cico/cico-header.component';
import UnauthorizedPage from '@pages/404.page';
import { Role } from '@shared/constants';
import { getAuthData } from '@store/entities/auth';
import React from 'react'
import { useSelector } from 'react-redux';

const CicoReconcilePage = () => {
  const { auth } = useSelector(getAuthData);
  const role = auth?.UserRole[0]?.name;
  let renderPage = <UnauthorizedPage />;

  if (auth) {
    switch (role) {
      case Role?.USER_SUPER_ADMIN:
        renderPage = <CicoHeaderComponent auth={auth} />
        break;
      default:
        break;
    }
  }
  return <>{renderPage}</>
}

export default CicoReconcilePage