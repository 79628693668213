import { SchedulerReconcileComponent } from '@components/module/reconcile/scheduler-reconcile.component';
import UnauthorizedPage from '@pages/404.page';
import { Role } from '@shared/constants';
import { getAuthData } from '@store/entities/auth';
import React from 'react'
import { useSelector } from 'react-redux';

const SchedulerReconcilePage = () => {
  const { auth } = useSelector(getAuthData);
  const role = auth?.UserRole[0]?.name;
  let renderPage = <UnauthorizedPage />;

  if (auth) {
    switch (role) {
      case Role?.USER_SUPER_ADMIN:
        renderPage = <SchedulerReconcileComponent />;
        break;
      default:
        break;
    }
  }

  return (<>{renderPage}</>)
}

export default SchedulerReconcilePage;