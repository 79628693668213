import { Role } from "@shared/constants";
import { IMenu } from "@shared/constants/menu.constant";
import { navAdminCA, navAdminPartner, navSuperAdmin, navUserAlgo, navUserCA, navUserPartner } from "@shared/constants/nav";
// import { navAdminInternal } from "@shared/constants/nav/admin-internal.constant";
import { MappingMenu } from "@shared/functions";
import { getAuthData } from "@store/entities/auth";
import React, { Fragment, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Header from "./header";
import NavHeader from "./nav-header";
import SideBar from "./side-bar";

const MainNav = ({ onClick: ClickToAddEvent }: any) => {
  const [menus, setMenus] = useState<IMenu[]>([]);
  const [title, setTitle] = useState<string>();
  const { auth } = useSelector(getAuthData);
  // const role = auth.UserRole[0].name;
  const [selectedMenu, setSelectedMenu] = useState<IMenu>();
  const [toggle, setToggle] = useState<string>("");

  useEffect(() => {
    const obj = {
      permissionList: auth?.UserRole[0]?.permissions?.map((data: any) => data?.name),
      navigationList: navSuperAdmin
    }
    const objMenu = MappingMenu(obj);
    setMenus(objMenu)
  }, [auth?.UserRole]);


  useEffect(() => {
    if (selectedMenu) {
      setTitle(selectedMenu.title);
    }
  }, [selectedMenu]);

  // useEffect(() => {
  //   if (auth) {
  //     switch (role) {
  //       case Role?.USER_SUPER_ADMIN:
  //         setMenus(navSuperAdmin);
  //         break;
  //       case Role?.USER_ADMIN_PARTNER:
  //         setMenus(navAdminPartner);
  //         break;
  //       case Role?.USER_ADMIN_CA:
  //         setMenus(navAdminCA);
  //         break;
  //       case Role?.USER_ADMIN_ALGO:
  //         setMenus(navAdminInternal);
  //         break;
  //       case Role?.USER_USER_ALGO:
  //         setMenus(navUserAlgo);
  //         break;
  //       case Role?.USER_USER_PARTNER:
  //         setMenus(navUserPartner);
  //         break;
  //       case Role?.USER_USER_CA:
  //         setMenus(navUserCA);
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // }, [auth, role]);

  const onClick = (name: SetStateAction<string>) =>
    setToggle(toggle === name ? "" : name);

  return (
    <Fragment>
      <NavHeader />
      {menus.length > 0 && (
        <SideBar menus={menus} setSelectedMenu={setSelectedMenu} />
      )}
      <Header
        onNote={() => onClick("chatbox")}
        onNotification={() => onClick("notification")}
        onProfile={() => onClick("profile")}
        toggle={toggle}
        title={title}
        onBox={() => onClick("box")}
        onClick={() => ClickToAddEvent()}
      />
    </Fragment>
  );
};

export default MainNav;
