import { Button, InputField } from "@components/common";
import { MONITORING } from "@shared/constants";
import { initialValuePurchaseMonitoring } from "@shared/constants/dtos/initialValue.dtos";
import { formPurchase } from "@shared/constants/form/purchase.form.constant";
import { capitalizeFirstLetter } from "@shared/functions";
import { IFormProps } from "@shared/interface/form.interface";
import { Form, Formik } from "formik";
import React, { Key, useMemo, useState } from "react";
import { Alert, Card, Col, Row } from "react-bootstrap";
import Table from "react-data-table-component";
import { SiMicrosoftexcel } from "react-icons/si";

export const PurchaseComponent = (props: any) => {
  const { type } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const handleSubmit = () => { };

  const contextActions = useMemo(() => {
    // const handleDownloadCSV = async () => {
    //   const body = {
    //     ...data,
    //     type
    //   };
    //   const newBody = removeEmptyObject(body);
    //   const res = await downloadCicoCsv(newBody);
    //   const url = window.URL.createObjectURL(new Blob([res.data]));
    //   const link = document.createElement('a');
    //   link.href = url;
    //   link.setAttribute('download', fileNameCSV());
    //   document.body.appendChild(link);
    //   link.click();
    // };

    return (
      <>
        <Button mode="success" onClick={() => { }} disabled={true}>
          <SiMicrosoftexcel />
          <span className={"ml-2"}>Export</span>
        </Button>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <h3>{type} Purchase</h3>
            </Card.Header>
            <Card.Body>
              <Formik
                enableReinitialize={true}
                // validationSchema={searchFormValidations(module)}
                initialValues={initialValuePurchaseMonitoring(null)}
                onSubmit={handleSubmit}
              >
                {({ errors }) => {
                  return (
                    <>
                      <Form>
                        <div className="form-row">
                          {formPurchase.map((data: IFormProps, i: Key) => {
                            return (
                              <InputField
                                field={data}
                                errors={errors}
                                key={i}
                                width={6}
                              />
                            );
                          })}
                        </div>
                        {errMsg && (
                          <Alert
                            className="mt-4"
                            style={{ width: "fit-content" }}
                            variant={"danger"}
                          >
                            {errMsg}
                          </Alert>
                        )}
                        <Button mode={"primary"}>
                          {isLoading ? "Loading..." : "Search"}
                        </Button>
                      </Form>
                    </>
                  );
                }}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            {/* <Card.Header>Table Purchase</Card.Header> */}
            <Card.Body>
              <Table title={"Table Purchase"} data={[]} columns={[]} actions={type === capitalizeFirstLetter(MONITORING) ? "" : contextActions} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
