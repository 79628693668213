import { Button } from "@components/common";
import { TableComponent } from "@components/tables/table.component";
import { capitalizeEachFirstWord } from "@shared/functions";
import { Formik } from "formik";
import React, { useState } from "react";
import { Alert, Card, Col, Row } from "react-bootstrap";

export const DepositWithdrawalComponent = (props: any) => {
  const { type, module } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [data, setData] = useState<any>(null);
  const [listData, setListData] = useState<any>([]);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  return (
    <>
      <Row>
        <Col sm={12}>
          <Card>
            <Card.Header>
              <h3>{capitalizeEachFirstWord(`${type} ${module}`)}</h3>
            </Card.Header>
            <Card.Body>
              {/* <Formik
                enableReinitialize={true}
                initialValues={initialValueCico(data)}
                onSubmit={handleSubmit}
              >
                {({ errors }) => {
                  return (
                    <>
                      <Form>
                        <div className="form-row">
                          {formSearchCicoReporting.map(
                            (data: IFormProps, i: Key) => {
                              return (
                                <InputField
                                  field={data}
                                  errors={errors}
                                  key={i}
                                  width={6}
                                />
                              );
                            }
                          )}
                        </div>
                        {errMsg && (
                          <Alert
                            className="mt-4"
                            style={{ width: "fit-content" }}
                            variant={"danger"}
                          >
                            {errMsg}
                          </Alert>
                        )}
                        <Button type="submit">
                          {isLoading ? "Loading..." : "Search"}
                        </Button>
                      </Form>
                    </>
                  );
                }}
              </Formik> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <TableComponent
        title={capitalizeEachFirstWord(`List ${type} ${module}`)}
        data={[]}
        columns={[]}
      />
    </>
  );
};
