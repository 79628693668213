import { Button, InputField } from "@components/common";
import {
  fetchBank,
  schedulerRCAlfaToAlgo,
  schedulerRCAlgoToPartner,
  schedulerRCPartnerToAlgo,
} from "@shared/api";
import { initialValueReconCico } from "@shared/constants/dtos/initialValue.dtos";
import { transactionDate } from "@shared/constants/form";
import { capitalizeEachFirstWord, convertDate } from "@shared/functions";
import { IFormProps } from "@shared/interface/form.interface";
import { Form, Formik } from "formik";
import React, { Key, useEffect, useState } from "react";
import { Alert, Card, Col, Row } from "react-bootstrap";

export const CicoReconcileComponent = () => {
  const [msg, setMsg] = useState<any>();
  const [bankList, setBankList] = useState([]);
  const getBank = async () => {
    const res = await fetchBank();
    if (res.status === 200) {
      const objData = res?.data?.banks?.map((data: any) => {
        return { label: data?.bank_name, value: data?.bank_code };
      });
      setBankList(objData);
    } else {
      setBankList([]);
    }
  };

  useEffect(() => {
    getBank();
  }, []);

  const formData = [
    transactionDate,
    {
      name: "bank_code",
      type: "select",
      label: "Bank",
      placeholder: "Choose Bank",
      options: bankList,
    },
  ];

  const handleSubmit = async (e: any) => {
    const obj = {
      date: convertDate(e.transaction_date),
      bank_code: e.bank_code,
    };

    const resAlfaAlgo = await schedulerRCAlfaToAlgo(obj);
    const resAlgoPartner = await schedulerRCAlgoToPartner(obj);
    const resPartnerAlgo = await schedulerRCPartnerToAlgo(obj);

    setMsg({
      alfa_algo:
        resAlfaAlgo?.status === 200
          ? {
              success: resAlfaAlgo.data?.message,
            }
          : {
              failed: resAlfaAlgo.data?.message,
            },
      algo_partner:
        resAlgoPartner?.status === 200
          ? {
              success: resAlgoPartner.data?.message,
            }
          : { failed: resAlgoPartner.data?.message },
      partner_algo:
        resPartnerAlgo?.status === 200
          ? {
              success: resPartnerAlgo.data?.message,
            }
          : { failed: resPartnerAlgo.data?.message },
    });
  };

  return (
    <Row>
      <Col>
        <Card>
          <Card.Header>CI/CO Scheduler</Card.Header>
          <Card.Body>
            <Row className="mb-3">
              <Col xs={12} md={12}>
                <Formik
                  enableReinitialize={true}
                  // validationSchema={searchFormValidations(module)}
                  initialValues={initialValueReconCico(null)}
                  onSubmit={handleSubmit}
                >
                  {({ errors }) => {
                    return (
                      <>
                        <Form>
                          <div className="form-row">
                            {formData.map((data: IFormProps, i: Key) => {
                              return (
                                <InputField
                                  field={data}
                                  errors={errors}
                                  key={i}
                                  width={6}
                                />
                              );
                            })}
                          </div>
                          {msg && (
                            <div className="col-4">
                              {Object.keys(msg)?.map((data: any, i: number) => {
                                return (
                                  <Alert
                                    key={i}
                                    className="mt-4"
                                    style={{ width: "fit-content" }}
                                    variant={
                                      msg[data]?.success ? "success" : "danger"
                                    }
                                  >
                                    Scheduler{" "}
                                    {capitalizeEachFirstWord(
                                      data.replaceAll("_", " ")
                                    )}{" "}
                                    is
                                    {msg[data]?.success}
                                    {msg[data]?.failed}
                                  </Alert>
                                );
                              })}
                            </div>
                          )}
                          <div className="col-5 d-flex justify-content-sm-between p-0">
                            <Button>Submit</Button>
                          </div>
                        </Form>
                      </>
                    );
                  }}
                </Formik>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
