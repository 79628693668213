import React, { InputHTMLAttributes } from 'react'
import { Col } from 'react-bootstrap'

interface IInputText extends InputHTMLAttributes<HTMLInputElement> {
  xs?: any;
  sm?: any;
  md?: any;
  label: string;
  placeholder?: string;
}

export const InputText = (props: IInputText) => {
  const { xs, md, sm, label, placeholder, ...rest } = props;
  return (
    <Col xs={xs} md={md} sm={sm} className="p-0">
      <label>{label}</label>
      <input className="form-control" placeholder={placeholder} {...rest} />
    </Col>
  )
}
