import { DepositWithdrawalComponent } from '@components/module/deposit - withdrawal/depositWithdrawal.component';
import UnauthorizedPage from '@pages/404.page';
import { REPORTING, Role } from '@shared/constants';
import { getAuthData } from '@store/entities/auth';
import React from 'react'
import { useSelector } from 'react-redux';

const ReportingDepositPage = () => {
  const { auth } = useSelector(getAuthData);
  const role = auth?.UserRole[0]?.name;
  let renderPage = <UnauthorizedPage />


  if (auth) {
    switch (role) {
      case Role?.USER_SUPER_ADMIN:
        renderPage = <DepositWithdrawalComponent type={REPORTING} module={"deposit"}  />
        break;
      default:
        break;
    }
  }
  return <>{renderPage}</>
}

export default ReportingDepositPage