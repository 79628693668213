import { Alert } from "react-bootstrap";

export const alertMessage = ({ status, message }: any) => {
  if (status === 200) {
    return (
      <Alert variant="success" style={{ width: "fit-content" }}>{message}</Alert>
    );
  } else {
    return (
      <Alert variant="danger" style={{ width: "fit-content" }}>{message}</Alert>
    );
  }
}