export const body = document.querySelector("body");
body?.setAttribute("data-typography", "poppins");
body?.setAttribute("data-theme-version", "light");
body?.setAttribute("data-layout", "vertical");
body?.setAttribute("data-nav-headerbg", "color_1");
body?.setAttribute("data-headerbg", "color_1");
body?.setAttribute("data-sidebar-style", "overlay");
body?.setAttribute("data-sibebarbg", "color_1");
body?.setAttribute("data-primary", "color_1");
body?.setAttribute("data-sidebar-position", "fixed");
body?.setAttribute("data-header-position", "fixed");
body?.setAttribute("data-container", "wide");
body?.setAttribute("direction", "ltr");

export const getPagePath = (width: number) => {
  width >= 768 && width < 1300
    ? body?.setAttribute("data-sidebar-style", "mini")
    : width <= 768
      ? body?.setAttribute("data-sidebar-style", "overlay")
      : body?.setAttribute("data-sidebar-style", "full");

  let path: string | string[] = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  return path.split("-").includes("page");
};


export let navigationList = [
  {
    url: "/",
    title: "Home",
    module: "all",
    icon: "<FiHome />",
    permission: ""
  },
  {
    url: "/monitoring",
    title: "Monitoring",
    module: "all",
    icon: "<FiMonitor />",
    subMenus: [
      {
        url: "/monitoring/payment-point",
        title: "Payment Point",
        module: "all",
        subMenus: [
          {
            url: "/monitoring/ca",
            title: "Collection Agents",
            module: "all",
            subMenus: [
              {
                url: "/monitoring/inquiry?ca",
                title: "Inquiry",
                module: "all",
                permission: "",
              },
              {
                url: "/monitoring/payment?ca",
                title: "Payment",
                module: "all",
                permission: "",
              },
              {
                url: "/monitoring/commit?ca",
                title: "Commit",
                module: "all",
                permission: "",
              },
            ],
          },
          {
            url: "/monitoring/partner",
            title: "Partner",
            module: "all",
            subMenus: [
              {
                url: "/monitoring/inquiry?partner",
                title: "Inquiry",
                module: "all",
                permission: "",
              },
              {
                url: "/monitoring/payment?partner",
                title: "Payment",
                module: "all",
                permission: "",
              },
            ],
          },
        ],
      },
      {
        url: "/monitoring/cash/in",
        title: "Cash In",
        module: "all",
        permission: "",
      },
      {
        url: "/monitoring/cash/out",
        title: "Cash Out",
        module: "all",
        permission: "",
      },
    ],
  },
  {
    url: "/reporting",
    title: "Reporting",
    module: "all",
    icon: "<FiClipboard />",
    subMenus: [
      {
        url: "/reporting/payment-point",
        title: "Payment Point",
        module: "all",
        subMenus: [
          {
            url: "/reporting/ca",
            title: "Collection Agents",
            module: "all",
            subMenus: [
              {
                url: "/reporting/inquiry?ca",
                title: "Inquiry",
                module: "all",
                permission: "settings.manage-user.activities",
              },
              {
                url: "/reporting/payment?ca",
                title: "Payment",
                module: "all",
                permission: "",
              },
              {
                url: "/reporting/commit?ca",
                title: "Commit",
                module: "all",
                permission: "",
              },
            ],
          },
          {
            url: "/reporting/partner",
            title: "Partner",
            module: "all",
            subMenus: [
              {
                url: "/reporting/inquiry?partner",
                title: "Inquiry",
                module: "all",
                permission: "",
              },
              {
                url: "/reporting/payment?partner",
                title: "Payment",
                module: "all",
                permission: "",
              },
            ],
          },
        ],
      },
      {
        url: "/reporting/cash/in",
        title: "Cash In",
        module: "all",
        permission: "",
      },
      {
        url: "/reporting/cash/out",
        title: "Cash Out",
        module: "all",
        permission: "",
      },
    ],
  },
  {
    url: "/reconcile",
    title: "Reconcile",
    module: "all",
    icon: "<AiFillReconciliation />",
    subMenus: [
      {
        url: "/reconcile/scheduler",
        title: "Scheduler",
        module: "all",
        permission: "",
      },
      {
        url: "/reconcile/list",
        title: "List CA",
        module: "all",
        permission: "",
      },
      {
        url: "/reconcile/list?success",
        title: "List Success CA",
        module: "all",
        permission: "",
      },
      {
        url: "/reconcile/match",
        title: "Match CA",
        module: "all",
        permission: "",
      },
      {
        url: "/reconcile/match?success",
        title: "Match List Success CA",
        module: "all",
        permission: "",
      },
      {
        url: "/reconcile/cico",
        title: "CI/CO",
        module: "all",
        permission: "",
      },
    ],
  },
  {
    url: "/user",
    title: "User",
    module: "all",
    icon: "<FiUsers />",
    subMenus: [
      {
        url: "/user/view",
        title: "User",
        module: "all",
        permission: "settings.manage-user.view"
      },
      {
        url: "/user/role",
        title: "Role",
        module: "all",
        permission: ""
      },
      {
        url: "/user/permission",
        title: "Permission",
        module: "all",
        permission: ""
      },
      {
        url: "/user/user-request",
        title: "Request",
        module: "all",
        permission: "settings.manage-user.create"
      },
      {
        url: "/user/user-request/list",
        title: "List Request",
        module: "all",
        permission: "settings.manage-user.list"
      },
    ],
  },
];

const permissions = [
  "settings.manage-role.permissions",
  "settings.manage-role.list",
  "settings.manage-role.create",
  "settings.manage-role.update",
  "settings.manage-role.view",
  "settings.manage-user.list",
  "settings.manage-user.create",
  "settings.manage-user.update",
  "settings.manage-user.view",
  "settings.manage-user.permissions",
  "settings.manage-user.update-block-status",
  "settings.manage-user.deactivate",
  "settings.manage-user.set-new-password",
  "settings.manage-user.activities",
  "dmi.transaction-monitoring.withdrawal",
  "dmi.transaction-monitoring.deposit",
];

function filterNav(nav: any, permissionMap: any) {
  var res = null;
  if (nav["permission"] !== undefined) {
    if (permissionMap[nav.permission] !== true) {
      return null;
    }
  }

  res = nav;
  if (nav["subMenus"] !== undefined && nav["subMenus"].length > 0) {
    res["subMenus"] = nav["subMenus"].filter(function (v: any) {
      const subNav = filterNav(v, permissionMap);
      return subNav !== null;
    });

    if (res["subMenus"].length === 0) {
      return null;
    }
  }
  return res;
}

export const MappingMenu = (props: any) => {
  const { permissionList, navigationList } = props;

  let permissionMap: any = {};
  permissionList.forEach((v: any, i: any) => {
    permissionMap[v] = true;
  });


  let navigationListFiltered: any = [];
  navigationList.forEach((v: any) => {
    const filteredNav = filterNav(v, permissionMap);
    if (filteredNav) {
      navigationListFiltered.push(filteredNav);
    }
  });

  return navigationListFiltered;
}