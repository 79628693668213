export const formUserCreate: any = [
  {
    name: "email",
    type: "text",
    label: "Email",
    placeholder: "Email",
  },
  {
    name: "role_id",
    type: "select",
    label: "Role",
    placeholder: "Role",
  },
  {
    name: "password",
    type: "password",
    label: "Password",
    placeholder: "Password"
  },
  // {
  //   name: "product_name",
  //   type: "autocomplete",
  //   label: "Product Name",
  //   placeholder: "Search Product Name",
  //   options: [],
  // }
]

export const formUserUpdate: any = [
  {
    name: "name",
    type: "text",
    label: "Name",
    placeholder: "Name",
  },
  {
    name: "status",
    type: "number",
    label: "Status",
    placeholder: "Status",
  },
]

export const formRequestUser: any = [
  {
    name: "email",
    type: "text",
    label: "Email",
    placeholder: "Cth: email@algo.com"
  },
  {
    name: "password",
    type: "password",
    label: "Password",
    placeholder: "Password"
  },
]

export const formChangePassword: any = [
  {
    name: "old_password",
    type: "password",
    label: "Old Password",
    placeholder: "Old Password"
  },
  {
    name: "new_password",
    type: "password",
    label: "New Password",
    placeholder: "New Password"
  },
  {
    name: "confirm_new_password",
    type: "password",
    label: "Confirm New Password",
    placeholder: "Confirm New Password"
  },
]