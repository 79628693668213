import { getUi, setIsSidebarOpen } from "@store/ui";
import { useDispatch, useSelector } from "react-redux";

/// React router dom
import { Link } from "react-router-dom";
import Logo from '@shared/assets/images/ALGO_LOGO.png';
import Icon_Logo from '@shared/assets/images/Icon_Algo-01.png';

const NavHeader = () => {
  // const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch();
  const { ui } = useSelector(getUi);

  return (
    <div className="nav-header">
      <Link to="/" className="brand-logo">
        {!ui.isSidebarOpen ?
          <img className="logo-abbr" src={Icon_Logo} alt="" />
          :
          ""
        }
        <img
          className="brand-title"
          src={Logo}
          alt=""
        />
      </Link>

      <div
        className="nav-control"
        onClick={() => dispatch(setIsSidebarOpen(!ui.isSidebarOpen))}
      >
        <div className={`hamburger ${!ui.isSidebarOpen ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHeader;
