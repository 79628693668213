import {
  BackButton,
  Button,
  DeleteModalComponent,
  InputText,
} from "@components/common";
import {
  deleteRoles,
  fetchPermissions,
  postCreateRole,
  putUpdateRole,
} from "@shared/api";
import { matchingArrToValue } from "@shared/functions";
import React, { useEffect, useState } from "react";
import { Alert, Card, Col, Form, Row } from "react-bootstrap";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { useNavigate } from "react-router";

export const DetailRoleComponent = (props: any) => {
  const { data, id } = props;
  const { permissions } = data ? data : "";
  const arrTemp = permissions?.map((data: any) => data?.name) || [];
  const [listPermissions, setListPermisions] = useState<any>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [response, setResponse] = useState<{
    status: number;
    message: string;
  }>();
  const [objData, setObjData] = useState<any>({
    name: "",
    description: "",
    permissions: [],
  });
  const navigate = useNavigate();

  const getListPermissions = async () => {
    const res = await fetchPermissions();
    const arrTempRes = res?.data?.data?.find((data: any) => data)?.permissions;
    setListPermisions(arrTempRes);
  };

  useEffect(() => {
    if (data) {
      setObjData(data);
    }
    if (listPermissions?.length === 0) {
      getListPermissions();
    }
  }, [data, listPermissions]);

  const handleCheckbox = (isChecked: boolean, data: any) => {
    if (isChecked) {
      setObjData({ ...objData, permissions: [...objData?.permissions, data] });
    } else {
      setObjData({
        ...objData,
        permissions: objData?.permissions?.filter(
          (val: any) => val.id !== data.id
        ),
      });
    }
  };

  const handleSubmit = async () => {
    const obj = {
      name: objData?.name,
      description: objData?.description,
      permission_ids: objData?.permissions?.map((data: any) => data?.id),
    };
    let res: { status: number; data: { message: string } };
    if (!data) {
      console.log({ ...obj });
      res = await postCreateRole(obj);
    } else {
      res = await putUpdateRole({ id, ...obj });
    }
    setResponse({ status: res?.status, message: res?.data?.message });
    setTimeout(() => {
      if (res?.status === 200) {
        navigate("/user/role");
      }
    }, 2500);
  };

  const handleDelete = async () => {
    const res = await deleteRoles(parseInt(id));
    setResponse({ status: res?.status, message: res?.data?.message });
    setShowDeleteModal(!showDeleteModal);
    setTimeout(() => {
      if (res?.status === 200) {
        navigate("/user/role");
      }
    }, 2500);
  };

  if (data) {
    if (arrTemp?.length === 0) {
      return <h3>Loading...</h3>;
    }
  }

  return (
    <>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <div className="d-flex align-content-center">
                <BackButton />
                <h3 className="m-0">{id ? "Update" : "Create"} Role</h3>
              </div>
              <div>
                <Button
                  style={{ marginRight: "0.5rem" }}
                  onClick={() => handleSubmit()}
                >
                  Submit
                </Button>
                {id && (
                  <Button
                    mode="danger"
                    onClick={() => setShowDeleteModal(!showDeleteModal)}
                  >
                    <RiDeleteBin2Fill />
                    Delete
                  </Button>
                )}
                {response && (
                  <Alert
                    className="my-2 p-2 text-center px-4"
                    style={{ width: "fit-content" }}
                    variant={response?.status === 200 ? "success" : "danger"}
                  >
                    {response?.message}
                  </Alert>
                )}
              </div>
            </Card.Header>
            <Card.Body>
              <Col md={4} className="p-0 mb-2">
                <InputText
                  label="Role Name"
                  value={objData?.name || ""}
                  onChange={(e: any) =>
                    setObjData({ ...objData, name: e.target.value })
                  }
                />
              </Col>
              <Col md={4} className="p-0 mb-4">
                <InputText
                  label="Description"
                  value={objData?.description || ""}
                  onChange={(e: any) =>
                    setObjData({ ...objData, description: e.target.value })
                  }
                />
              </Col>
              <label style={{ marginBottom: "1rem" }}>Permissions</label>
              <ul className="f-14">
                {listPermissions?.length > 0
                  ? listPermissions?.map((data: any, i: any) => {
                      return (
                        <li key={i}>
                          <div className="d-flex align-content-center mb-2">
                            <label className="d-flex justify-content-between align-items-start w-100 mr-2">
                              <Form.Check
                                type={"checkbox"}
                                id={`default-checkbox`}
                                label={data?.name}
                                defaultChecked={
                                  arrTemp?.length > 0
                                    ? matchingArrToValue(arrTemp, data?.name)
                                    : false
                                }
                                onChange={(e: any) =>
                                  handleCheckbox(e.target.checked, data)
                                }
                              />
                              <p className="figure-caption">
                                {data?.description}
                              </p>
                            </label>
                          </div>
                        </li>
                      );
                    })
                  : ""}
              </ul>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <DeleteModalComponent
        onSubmit={() => handleDelete()}
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(!showDeleteModal)}
        alertMessage={`Role ${objData?.name || "Data ini"} akan dihapus`}
      />
    </>
  );
};
