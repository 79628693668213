import { Button } from '@components/common';
import { downloadCSV } from '@shared/api';
import { BILLER, REPORTING } from '@shared/constants';
import { convertDate } from '@shared/functions';
import React, { useEffect, useMemo, useState } from 'react'
import { Badge, Card, Col, Row } from 'react-bootstrap'
import Table from 'react-data-table-component';
import { SiMicrosoftexcel } from 'react-icons/si';
interface IDataTableMonitoringComponent {
  title: string;
  data: any[];
  type?: string;
  loading?: boolean;
  paginationTotalRows: any;
  onChangePage: any;
  onChangeRowsPerPage: any;
  module: any;
  paramSearch?: any;
  moduleType: any;
  handleOpenModal?: any;
}

export const DataTableMonitoringComponent = (props: IDataTableMonitoringComponent) => {
  const {
    title,
    data,
    type,
    module,
    moduleType,
    loading,
    paginationTotalRows,
    onChangePage,
    onChangeRowsPerPage,
    paramSearch,
    handleOpenModal
  } = props;
  const [columns, setColumns] = useState<any>([]);
  const transaction_date = paramSearch ? convertDate(paramSearch.transaction_date) : "";
  const fileNameCSV = () => `${module}_${moduleType}_${type}_${transaction_date}.csv`;

  const actionColumn = [
    {
      name: "Response From Biller",
      button: true,
      cell: (row: any) => (
        <>
          {row.response_message === "" ?
            <Badge bg="danger" text="light">No Data</Badge>
            :
            <Button onClick={() => handleOpenModal(row.response_message, "Response")}>
              More
            </Button>
          }
        </>
      ),
    },
    {
      name: "Request To Biller",
      button: true,
      cell: (row: any) => (
        <>
          {row.request_message === "" ?
            <Badge bg="danger" text="light">No Data</Badge>
            :
            <Button onClick={() => handleOpenModal(row.request_message, "Request")}>
              More
            </Button>
          }
        </>
      ),
    },
  ]

  const defaultColumn = [
    {
      width: "12ch",
      name: moduleType === "commit" ? "Payment ID" : "Inquiry ID",
      selector: (row: any) => moduleType === "commit" ? row.payment_id : row.inquiry_id,
      sortable: true,
    },
    {
      width: "30ch",
      name: "Transaction ID",
      selector: (row: any) => row.transaction_id,
      sortable: true,
    },
    {
      name: "Partner ID",
      selector: (row: any) => row.partner_id,
      sortable: true,
    },
    {
      name: "Product Code",
      selector: (row: any) => row.product_code,
      sortable: true,
    },
    {
      width: "25ch",
      name: "Customer ID",
      selector: (row: any) => row.customer_id,
      sortable: true,
    },
    {
      name: "Customer Name",
      selector: (row: any) => row.customer_name,
      sortable: true,
    },
    {
      name: "State",
      selector: (row: any) => row.state,
      sortable: true,
    },
    {
      width: "30ch",
      name: "Transaction Date",
      selector: (row: any) => row.transaction_date,
      sortable: true,
    },
    {
      name: "Terminal ID",
      selector: (row: any) => row.terminal_id,
      sortable: true,
    },
    {
      name: "Tracking Ref",
      selector: (row: any) => row.tracking_ref,
      sortable: true,
    },
    {
      name: "RC",
      selector: (row: any) => row.response_code,
      sortable: true,
      width: "12ch",
    }
  ]

  useEffect(() => {
    if (type === BILLER) {
      setColumns([...defaultColumn, ...actionColumn]);
    }
    else {
      setColumns(defaultColumn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [module]);

  const contextActions = useMemo(() => {
    const handleDownloadCSV = async () => {
      const body = {
        ...paramSearch,
        module: moduleType,
        type_org: type,
        transaction_date
      }
      const res = await downloadCSV(body);
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileNameCSV());
      document.body.appendChild(link);
      link.click();
    };

    return (
      <>
        <Button mode="success" onClick={() => handleDownloadCSV()}>
          <SiMicrosoftexcel />
          <span className={"ml-2"}>Export</span>
        </Button>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramSearch]);

  return (
    <Row>
      <Col sm={12}>
        <Card>
          <Card.Body>
            <Table
              style={{ overflow: 'wrap' }}
              columns={columns}
              title={title}
              data={data}
              pagination
              paginationServer
              paginationTotalRows={paginationTotalRows}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              progressPending={loading}
              actions={module === REPORTING ? contextActions : ""}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}