import { Button, SearchInputComponent } from "@components/common";
import { getRole } from "@store/entities/role";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Table from "react-data-table-component";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { columnnRole } from "../data-table/data-table.component";

export const ListRoleComponent = (props: any) => {
  const { perPage, handlePerRowsChange, handlePageChange } = props;
  const { role: { roleList, length } } = useSelector(getRole);
  const [listData, setListData] = useState([]);

  useEffect(() => {
    if (roleList.length > 0) {
      setListData(roleList);
    }
  }, [roleList])

  return (
    <Row>
      <Col>
        <Card>
          <Card.Header>
            <h3>Role</h3>
            <Link to="/user/role/create">
              <Button>Create</Button>
            </Link>
          </Card.Header>
          <Card.Body>
            <div className="col-3 w-100 p-0">
              <SearchInputComponent
                data={roleList}
                onFilter={(data: any) => setListData(data)}
                placeholder="Search Role Name"
                paramFilter={"name"}
              />
            </div>
            <Table
              columns={columnnRole}
              data={listData}
              pagination
              paginationServer
              paginationPerPage={perPage}
              paginationTotalRows={length}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
