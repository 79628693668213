import { randomPassword } from "@shared/functions"

export const initialValueUser = (data: any) => {
  return {
    email: data?.email || "",
    password: data?.password || randomPassword(),
    status: data?.status || "",
    role_id: data?.role_id || "",
    name: data?.name || "",
    id: data?.id || "",
    product_name: data?.product_name || "",
  }
}

export const initialValueUserRequest = (data: any) => {
  return {
    email: data?.email || "",
    password: data?.password || randomPassword(),
  }
}

export const initialValueChangePassword = (data: any) => {
  return {
    old_password: data?.old_password || "",
    new_password: data?.new_password || "",
    confirm_new_password: data?.confirm_new_password || "",
  }
}