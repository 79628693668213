export const formSearchCicoReporting: any = [
  {
    name: "account_number",
    type: "text",
    label: "No. Account",
    placeholder: "e.g 12321313",
  },
  {
    name: "sender_name",
    type: "text",
    label: "Sender Name",
    placeholder: "e.g Andri",
  },
  {
    name: "recipient_name",
    type: "text",
    label: "Recipient Name",
    placeholder: "e.g Citra",
  },
]