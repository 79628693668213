import { IInputField } from '@shared/interface/form.interface'
import { AutoCompleteFieldComponent } from './auto-complete.input';
import { CheckboxFieldComponent } from './checkbox.input';
import { DatePickerField } from './date-picker.input';
import { PasswordFieldInput } from './password.input';
import { SelectFieldComponent } from './select.input';
import { TextFieldInput } from './text.input';

export const InputField = (props: IInputField) => {
  const { field, errors, width, callbackSelect } = props;
  let typeField: any;
  switch (field.type) {
    case "text":
    case "email":
    case "number":
      typeField = <TextFieldInput field={field} errors={errors} />;
      break;
    case "password":
      typeField = <PasswordFieldInput field={field} errors={errors} />;
      break;
    case "checkbox":
    case "radio":
      typeField = <CheckboxFieldComponent field={field} />;
      break;
    case "select":
      typeField = <SelectFieldComponent field={field} errors={errors} />;
      break;
    case "datepicker":
      typeField = <DatePickerField field={field} errors={errors} />;
      break;
    case "autocomplete":
      typeField = <AutoCompleteFieldComponent field={field} errors={errors}
        callback={(e: any) => callbackSelect && callbackSelect(e, "select")}
      />;
      break;
    default:
      break;
  }
  return (
    <div className={`form-group col-sm-${width ? width : '12'}`} key={field.name}>
      <label className="col-form-label">{field.label}</label>
      <div className="col p-0">{typeField}</div>
    </div>
  );
}