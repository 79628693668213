import { DetailRoleComponent } from '@components/module/user/role/detail-role.component';
import UnauthorizedPage from '@pages/404.page';
import { fetchRoles } from '@shared/api';
import { Role } from '@shared/constants';
import { getAuthData } from '@store/entities/auth';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

const DetailRolePage = () => {
  const { auth } = useSelector(getAuthData);
  const role = auth?.UserRole[0]?.name;
  let renderPage = <UnauthorizedPage />;
  const [dataRole, setDataRole] = useState<any>([]);
  const { id } = useParams();
  const fetchRoleById = async () => {
    const res = await fetchRoles(id);
    setDataRole(res?.data);
  }
  useEffect(() => {
    if (id) {
      fetchRoleById();
    }
  }, []);

  if (auth) {
    switch (role) {
      case Role?.USER_SUPER_ADMIN:
        renderPage = <DetailRoleComponent auth={auth} data={dataRole} id={id} />
        break;
      default:
        break;
    }
  }
  return <>{renderPage}</>
}

export default DetailRolePage