import { Button } from "@components/common";
import MainNav from "@components/layouts/nav";
import Footer from "@components/layouts/nav/footer";
import { getPagePath } from "@shared/functions";
import { fetchLogout } from "@store/entities/auth";
import { getUi } from "@store/ui";
import { useEffect, useState } from "react";
import { Alert, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const LayoutRouter = (props: any) => {
  const { access_token } = props.auth;
  const { ui } = useSelector(getUi);
  const path: string | string[] = window.location.pathname;
  const pagePath = getPagePath(props.width);
  const isLoggedIn = access_token && !pagePath;
  const [activeEvent] = useState(!path);
  const isPageClass = isLoggedIn ? "show" : "mh100vh";
  const isCloseSidebarClass = !ui?.isSidebarOpen ? "menu-toggle" : "";
  const [expiredTime, setExpiredTime] = useState(0);
  const nowTime = new Date().getTime();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (access_token) {
      const decodedToken = JSON.parse(atob(access_token.split('.')[1]));
      setExpiredTime(decodedToken.exp);
    }
  }, [access_token]);

  return (
    <div
      id={`${isLoggedIn ? "main-wrapper" : ""}`}
      className={`${isPageClass} ${isCloseSidebarClass}`}
    >
      {isLoggedIn && <MainNav />}
      <div
        className={` ${!path && activeEvent ? "rightside-event" : ""} ${isLoggedIn ? "content-body" : ""}`}
      >
        <div
          className={`${isLoggedIn ? "container-fluid" : ""}`}
          style={{
            minHeight: "calc(100vh - 175px)",
            background: "#f7f7f7",
          }}
        >
          {props.children}
        </div>
      </div>
      {!pagePath && <Footer />}

      {access_token &&
        <Modal
          show={expiredTime < nowTime / 1000 ? true : false}
          centered
        >
          <Modal.Header>Expired Session</Modal.Header>
          <Modal.Body className="text-center">
            <Alert className="mb-2">
              <h4>Your login session is expired please click button below to do login</h4>
            </Alert>
            <Button onClick={() => { dispatch(fetchLogout()); navigate("/login-page"); }}>Go To Login</Button>
          </Modal.Body>
        </Modal>
      }
    </div>
  );
};
