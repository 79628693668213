export const initialValueSearchCustomerTransaction = (data: any) => {
  return {
    transaction_type: data?.transaction_type || "",
    search_type: data?.transaction_type || "",
    transaction_date: data?.transaction_date || "",
    number: data?.number || "",
  }
}

export const initialValueFilterMonitoring = (data: any) => {
  return {
    customer_id: data?.customer_id || "",
    trx_id: data?.trx_id || "",
    status: data?.status || "",
    state: data?.state || "",
    response_code: data?.response_code || "",
    transaction_date: data?.transaction_date || "",
  }
}

export const initialValueCico = (data: any) => {
  return {
    account_number: data?.account_number || "",
    recipient_name: data?.recipient_name || "",
    sender_name: data?.sender_name || "",
  }
}

export const initialValueReconCico = (data: any) => {
  return {
    date: data?.date,
    bank_code: data?.bank_code
  }
}

export const initialValueCashInVirgo = (data: any) => {
  return {
    transaction_id: data?.transaction_id || 0,
    customer_number: data?.customer_number || "",
    algo_state: data?.algo_state || "",
  }
}

export const initialValuePurchaseMonitoring = (data:any) => {
  return {
    transaction_id: data?.transaction_id || "",
    product_id: data?.product_id || "",
    customer_number: data?.customer_number || "",
  }
}