import { BackButton, DatePickerRangeDate } from '@components/common'
import { convertDate, removeEmptyObject } from '@shared/functions';
import { fetchReconcileCicoDetail, getReconcile } from '@store/entities/reconcile';
import React, { Key, useEffect, useState } from 'react'
import { Card, Col, Row, Tab, Nav } from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { columnDetailCico, columnHeaderCico } from '../../cico/data-tables/columns.cico';

export const DetailCicoComponent = (props: any) => {
  const { reconId } = props;
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const { reconcile } = useSelector(getReconcile);
  const { listCicoHeader, listCicoDetail } = reconcile;
  const [selectedTab, setSelectedTab] = useState<string>("cash in");
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    const obj = {
      type: selectedTab === "cash in" ? "cash-in" : "cash-out",
      recon_id: reconId,
      start_date: startDate ? convertDate(startDate) : "",
      end_date: endDate ? convertDate(endDate) : "",
      limit: perPage,
      page: page,
    }
    if(reconId){
      dispatch(fetchReconcileCicoDetail(removeEmptyObject(obj)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, reconId, perPage, page, selectedTab]);

  const tabData = [
    {
      name: "Cash In",
      data: listCicoDetail?.data,
      total_row: listCicoDetail?.total_row,
    },
    {
      name: "Cash Out",
      data: listCicoDetail?.data,
      total_row: listCicoDetail?.total_row,
    },
  ];

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setPerPage(newPerPage);
    setPage(page);
  };

  return (
    <>
      <Row>
        <Col sm={12}>
          <Card>
            <Card.Header>
              <div className="d-flex align-content-center">
                <BackButton />
                <h3 className="m-0">Reconcile Cico Detail</h3>
              </div>
            </Card.Header>
            <Card.Body>
              <table className="table table-hover">
                <tbody>
                  {listCicoHeader?.data?.length > 0 && columnHeaderCico.slice(1).map((data: any, i: number) => (
                    <tr key={i}>
                      <td>{data.name}</td>
                      <td>{listCicoHeader?.data[0][data.value]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title>Product Table</Card.Title>
              <Col xl={3}>
                <DatePickerRangeDate startDate={startDate} endDate={endDate} setDateRange={setDateRange} />
              </Col>
            </Card.Header>
            <Card.Body>
              <div className="custom-tab-1">
                <Tab.Container
                  activeKey={selectedTab}
                  onSelect={(e: any) => setSelectedTab(e)}
                >
                  <Nav as="ul" className="nav-tabs">
                    {tabData.map((data: any, i: Key) => (
                      <Nav.Item as="li" key={i} className="c-pointer">
                        <Nav.Link eventKey={data.name.toLowerCase()}>
                          {data.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <Tab.Content className="pt-4">
                    {tabData.map((data: any, i: Key) => (
                      <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                        <DataTable
                          columns={columnDetailCico}
                          data={data?.data || []}
                          pagination
                          paginationServer
                          paginationTotalRows={data?.total_row}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                        />
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}
