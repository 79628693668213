// import { CommitComponent } from '@components/module/commit/commit.component';
// import UnauthorizedPage from '@pages/404.page';
import { BILLER, CA, MONITORING, Role } from '@shared/constants';
import { getAuthData } from '@store/entities/auth';
import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { lazily } from "react-lazily";

const UnauthorizedPage = lazy(() => import('@pages/404.page'));
const { CommitComponent } = lazily(() => import('@components/module/commit/commit.component'));

const CommitMonitoringPage = () => {
  const { auth } = useSelector(getAuthData);
  const location = useLocation();
  const role = auth?.UserRole[0]?.name;
  let renderPage = <UnauthorizedPage />

  if (auth) {
    switch (role) {
      case Role?.USER_SUPER_ADMIN:
      case Role?.USER_ADMIN_ALGO:
      case Role?.USER_USER_ALGO:
        if (location.search === Role?.USER_CA) {
          renderPage = <CommitComponent auth={auth} typeOrg={CA} module={MONITORING} />
        } else {
          renderPage = <CommitComponent auth={auth} typeOrg={BILLER} module={MONITORING} />
        }
        break;
      case Role?.USER_ADMIN_CA:
      case Role?.USER_USER_CA:
        renderPage = <CommitComponent auth={auth} typeOrg={CA} module={MONITORING} />
        break;
      case Role?.USER_ADMIN_PARTNER:
      case Role?.USER_USER_PARTNER:
        renderPage = <CommitComponent auth={auth} typeOrg={BILLER} module={MONITORING} />
        break;
      default:
        break;
    }
  }

  return (<>{renderPage}</>)
}
export default CommitMonitoringPage;