import { createSlice, createSelector } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "ui",
  initialState: { isSidebarOpen: true, error: "" },
  reducers: {
    setIsSidebarOpen: (ui: any, action: any) => {
      ui.isSidebarOpen = action.payload;
    },
  },
});

export const { setIsSidebarOpen } = slice.actions;
export default slice.reducer;

export const getUi = createSelector(
  (state: any) => ({ ui: state.ui }),
  (state) => state
);