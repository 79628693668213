import {
  Button,
  ModalFormComponent,
  SearchInputComponent,
} from "@components/common";
import { fetchProductList } from "@shared/api";
import { RES_STATUS } from "@shared/constants";
import { formUserCreate } from "@shared/constants/form/user.form.constant";
import { mappingFormRole, removeEmptyObject } from "@shared/functions";
import { userFormValidation } from "@shared/validations/user.validation";
import { fetchAllRole, getRoleList } from "@store/entities/role";
import {
  createUser,
  getUserList,
  getUser,
  clearStatus,
  fetchAllUser,
} from "@store/entities/user";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Table from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { columnsUser } from "../data-table/data-table.component";
import { initialValueUser } from "../dtos/user.dto";

export const UserListComponent = (props: any) => {
  const { userList } = useSelector(getUserList);
  const { user } = useSelector(getUser);
  const { roleList } = useSelector(getRoleList);
  const [formData, setFormData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [perPage, setPerPage] = useState(30);
  const [page, setPage] = useState(1);
  const [isUpdate, setIsUpdate] = useState(false);
  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState<any>([]);
  const [listData, setListData] = useState<any>(userList);
  const dispatch = useDispatch();

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setPerPage(newPerPage);
    setPage(page);
  };

  useEffect(() => {
    if (userList.length > 0) {
      setListData(userList);
    }
  }, [userList]);

  useEffect(() => {
    const params = {
      limit: perPage,
      page: page,
    };
    dispatch(fetchAllUser(params));
    dispatch(fetchAllRole({}));
    setListData(userList);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, page, perPage]);

  useEffect(() => {
    setIsUpdate(false);
    const formProduct = {
      name: "product_name",
      type: "autocomplete",
      label: "Product Name",
      placeholder: "Search Product Name",
      options: productList,
      onSearch: (val: any) => handleSearchProduct(val),
      onChange: (newValue: any) => setSelectedProduct(newValue),
      value: selectedProduct,
    };
    const dataAfterMapRole = mappingFormRole(formUserCreate, roleList);
    const newData: any = [...dataAfterMapRole, formProduct];
    setFormData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleList, productList, selectedProduct]);

  const actionTable = (
    <div className="col-3 w-100 p-0">
      <SearchInputComponent
        data={userList?.data}
        onFilter={(data: any) => setListData({ data })}
        placeholder="Search Email"
        paramFilter={"email"}
      />
    </div>
  );

  const handleSubmit = async (e: any, type?: string) => {
    dispatch(clearStatus());
    setIsLoading(false);
    const arrProducts = selectedProduct.map((data: any) => {
      return { product_id: data.value, provider_id: data.provider_id };
    });
    const clearEmpty = removeEmptyObject(e);
    const body = {
      ...clearEmpty,
      role_id: [parseInt(clearEmpty.role_id)],
      product_provider: arrProducts,
    };
    dispatch(createUser(body));
    if (user.status === RES_STATUS.SUCCEEDED) {
      setTimeout(() => {
        setIsLoading(true);
        setShowModal(!showModal);
        setErrorMessage("");
      }, 2000);
    } else {
      setIsLoading(false);
      setErrorMessage("Submit user failed!");
    }
  };

  const handleSearchProduct = async (val: any) => {
    if (val.length >= 3) {
      const params = {
        product_name: val,
        limit: 20,
        page: 1,
      };
      const searchLocal = productList.filter(
        (e: any) => e.label.toLowerCase().indexOf(val.toLowerCase()) > -1
      );
      if (searchLocal.length > 0) {
        setProductList(searchLocal);
      } else {
        const res = await fetchProductList(params);
        if (res.status === 200 && res.data.data !== null) {
          const data = res.data.data.map((data: any) => {
            return {
              value: data.product_id,
              label: data.product_name,
              provider_id: data.provider_id,
            };
          });
          setProductList(data);
        } else {
          setProductList([]);
        }
      }
    }
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <Card>
            <Card.Header>
              <h3>List User</h3>
              <Button
                onClick={() => {
                  setShowModal(!showModal);
                  setData(null);
                  setIsUpdate(false);
                }}
              >
                Create
              </Button>
            </Card.Header>
            <Card.Body>
              {actionTable}
              <Table
                columns={columnsUser}
                data={listData?.data || []}
                pagination
                paginationServer
                paginationPerPage={perPage}
                paginationTotalRows={userList?.length}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <ModalFormComponent
        show={showModal}
        onHide={() => setShowModal(!showModal)}
        title={`${isUpdate ? "Update" : "Create"} User`}
        onSubmit={(e: any) => handleSubmit(e)}
        initialValue={initialValueUser(data)}
        formData={formData}
        validationSchema={userFormValidation}
        alertMessage={errorMessage}
      />
    </>
  );
};
