
export const formSearchMonitoring: any = [
  {
    name: "customer_id",
    type: "text",
    label: "Customer ID",
    placeholder: "Input Customer ID",
  },
  {
    name: "transaction_id",
    type: "text",
    label: "Transaction ID",
    placeholder: "Input Transaction ID",
  },
  {
    name: "response_code",
    type: "text",
    label: "RC",
    placeholder: "Input RC"
  }
]

export const optionStatePayment: any = {
  name: "state",
  type: "select",
  label: "State",
  placeholder: "Choose State",
  options: [
    { label: "Success", value: "SUCCESS" },
    { label: "Pending", value: "PENDING" },
    { label: "Failed", value: "FAILED" }
  ]
}

export const optionStateDefault: any = {
  name: "state",
  type: "select",
  label: "State",
  placeholder: "Choose State",
  options: [
    { label: "Success", value: "SUCCESS" },
    { label: "Failed", value: "FAILED" }
  ]
}

export const formStatus: any = {
  name: "status",
  type: "select",
  label: "Status",
  placeholder: "Choose Status",
}


export const optionsPending: any = [
  { label: "To Commit", value: "TO_COMMIT" },
  { label: "Committed", value: "COMMITTED" },
]

export const optionSuccess: any = [
  { label: "Transacted", value: "TRANSACTED" },
]

export const optionFailed: any = [
  { label: "Failed", value: "FAILED" },
]

export const transactionDate: any = {
  name: "transaction_date",
  type: "datepicker",
  label: "Transaction Date",
  placeholder: "Input Transaction Date",
}