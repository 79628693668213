import { Field } from "formik";

interface IFormField {
  name: string;
  type: string;
  placeholder?: string;
  disabled?: boolean;
}

export const FieldForm = ({
  name,
  type,
  placeholder,
  disabled,
}: IFormField) => {
  return (
    <>
      <div className="form-group">
        <Field
          disabled={disabled}
          id={name}
          name={name}
          type={type}
          placeholder={placeholder}
          className="form-control"
        />
      </div>
    </>
  );
};
