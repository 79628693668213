import { DatePickerRangeDate } from '@components/common';
import React, { useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import Table from 'react-data-table-component';

export const PurchaseComponent = (props: any) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const FilterForm = () => {
    return (
      <Col xl={3}>
        <DatePickerRangeDate startDate={startDate} endDate={endDate} setDateRange={setDateRange} />
      </Col>
    );
  }
  return (
    <Row>
      <Col>
        <Card>
          <Card.Header>Purchase Header
            <FilterForm />
          </Card.Header>
          <Card.Body>
            <Table
              columns={[]}
              data={[]}
            // pagination
            // paginationServer
            // paginationTotalRows={listCicoHeader?.total_row}
            // onChangeRowsPerPage={handlePerRowsChange}
            // onChangePage={handlePageChange}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}
