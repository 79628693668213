import { combineReducers } from "redux";
import authReducer from "./auth";
import userReducer from "./user";
import roleReducer from "./role";
import reconcileHeader from "./reconcile";

export default combineReducers({
  auth: authReducer,
  user: userReducer,
  role: roleReducer,
  reconcile: reconcileHeader,
});
