import React from 'react'
import { Modal } from 'react-bootstrap';
import { ImCancelCircle } from 'react-icons/im';
import { Button } from '..';

interface IModalDetail {
  show: boolean;
  onHide: any;
  title: string;
  objDetail: any;
}

export const ModalDetailTableCard = (props: IModalDetail) => {
  const {
    show,
    onHide,
    title,
    objDetail,
  } = props;

  return (
    <Modal
      show={show}
      centered
      onHide={onHide}
      size="xl"
    >
      <Modal.Header>Detail Info</Modal.Header>
      <Modal.Body>
        <Modal.Title className="mb-3">{title}</Modal.Title>
        {Object.keys(objDetail).length > 0 ?
          <table className="w-100">
            <tbody>
              {Object.keys(objDetail).map((key, index) => {
                let data;
                if (key === "type") return "";
                if (key === "additional_data") {
                  if (objDetail[key] !== "") {
                    const obj = JSON.parse(objDetail[key]);
                    const mapping = Object.keys(obj).map((key, i) => (<span key={i}>{key + " = " + obj[key]}<br /></span>));
                    data = mapping.map((val) => val);
                  }
                  else {
                    data = "";
                  }
                } else {
                  data = objDetail[key];
                }
                return (
                  <tr key={index}>
                    <td className="border border-primary p-2">{key}</td>
                    <td className="w-75 border border-primary p-2">{data}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          :
          "Data Not Found"}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button mode="danger" onClick={onHide}>
            <ImCancelCircle className="m-1" />
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
