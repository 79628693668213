import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { FiUser, FiLogOut } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { assemblyRole, getFirstWord } from "@shared/functions";

import { fetchLogout, getAuthData } from "@store/entities/auth";
import { IconContext } from "react-icons";

const Header = ({ title }: any) => {
  const dispatch = useDispatch();
  const { auth } = useSelector(getAuthData);
  const navigate = useNavigate();

  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
                {/* {!title
                  ? getTitlePage()
                  : title === "Home"
                    ? "Dashboard"
                    : title} */}
              </div>
            </div>

            <ul className="navbar-nav header-right">
              <Dropdown as="li" className="nav-item header-profile ">
                <Dropdown.Toggle
                  as="a"
                  variant=""
                  className="nav-link i-false c-pointer"
                >
                  <div className="header-info">
                    <span className="text-black">
                      Hello, <strong>{getFirstWord(auth?.Username)}</strong>
                    </span>
                    <p className="fs-12 mb-0">
                      {assemblyRole(auth?.UserRole[0].name)}
                    </p>
                  </div>
                  <IconContext.Provider value={{ color: "#00CFFF" }}>
                    <div>
                      <FiUser />
                    </div>
                  </IconContext.Provider>
                </Dropdown.Toggle>
                <Dropdown.Menu className="mt-2">
                  <Link to="/profile" className="dropdown-item ai-icon">
                    <FiUser />
                    <span className="ml-2">Profile </span>
                  </Link>
                  <div
                    style={{ cursor: "pointer" }}
                    className="dropdown-item ai-icon"
                    onClick={() => {
                      dispatch(fetchLogout());
                      navigate("/login-page");
                    }}
                  >
                    <FiLogOut />
                    <span className="ml-2">Logout </span>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
