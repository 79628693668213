// import TitlePage from '@components/layouts/title.layout'
// import { getAuth, getAuthData } from '@store/entities/auth'
import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
// import { useSelector } from 'react-redux'

export const HomeComponent = (props: any) => {
  // const { auth } = useSelector(getAuthData);
  return (
    <>
      <Row>
        <Col xs={12}>
          <Card>
            <Card.Body>
              Admin Aladin
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}