import { Modal } from 'react-bootstrap'
import { Button } from '..';
interface IModalFormComponent {
  show: boolean;
  onHide?: () => void;
  onSubmit: (e: any) => void;
  alertMessage?: any;
}
export const DeleteModalComponent = (props: IModalFormComponent) => {
  var {
    show,
    onHide,
    onSubmit,
    alertMessage,
  } = props;
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete Confirmation!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-0">
          Are You Sure want to delete this data ?
          <br />
          {alertMessage || "Deleted data cannot be recovered"}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button mode="secondary" onClick={onHide}>
          Tutup
        </Button>
        <Button mode="danger" onClick={onSubmit}>
          Hapus
        </Button>
      </Modal.Footer>
    </Modal>
  )
}