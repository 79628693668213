import { createAsyncThunk, createSlice, createSelector } from "@reduxjs/toolkit";
import { api } from "@shared/api";
import { api_url, RES_IDLE, RES_STATUS } from "@shared/constants";
import { assemblyParameters, setPending, setRejected, setSucceed } from "@shared/functions";


export const fetchAllUser = createAsyncThunk(
  "user/FETCH_ALL",
  async (params: any, { rejectWithValue }) => {
    try {
      const paramQuery = assemblyParameters(params);
      const res = await api.get(`${api_url}/v1/users?${paramQuery}`);
      return res.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.response.data.message });
    }
  }
);

export const fetchUserById = createAsyncThunk(
  "user/FETCH_BY_ID",
  async (id: any, { rejectWithValue }) => {
    try {
      const res = await api.get(`${api_url}/v1/users/${id}`);
      return res.data.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.response.data.message });
    }
  }
)

export const createUser = createAsyncThunk(
  "user/CREATE",
  async (body: any, { rejectWithValue }) => {
    try {
      const res = await api.post(`${api_url}/v1/users`, body);
      return res.data.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.response.data.message });
    }
  }
)

export const updateUser = createAsyncThunk(
  "user/UPDATE",
  async (params: any, { rejectWithValue }) => {
    try {
      const { id, ...rest } = params;
      const res = await api.put(`${api_url}/v1/users/${id}`, rest);
      return res.data.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.response.data.message });
    }
  }
)

export const deactivateUser = createAsyncThunk(
  "user/DEACTIVATE",
  async (body: any, { rejectWithValue }) => {
    try {
      const res = await api.put(`${api_url}/v1/users/deactivate`, body);
      return res.data.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.response.data.message });
    }
  }
)

export const createRequestUser = createAsyncThunk(
  "user/CREATE_REQUEST",
  async (body: any, { rejectWithValue }) => {
    try {
      const res = await api.post(`${api_url}/v1/users/requests`, body);
      return res.data.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.response.data.message });
    }
  }
)

export const fetchRequestUser = createAsyncThunk(
  "user/FETCH_REQUEST",
  async (params: any, { rejectWithValue }) => {
    try {
      const paramQuery = assemblyParameters(params);
      const res = await api.get(`${api_url}/v1/users/requests?${paramQuery}`);
      return res.data.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.response.data.message });
    }
  }
)

export const approveRequestUser = createAsyncThunk(
  "user/APPROVE_REQUEST",
  async (body: any, { rejectWithValue }) => {
    try {
      const { request_id, ...rest } = body;
      const res = await api.post(`${api_url}/v1/users/requests/${request_id}/approve`, rest);
      return res.data.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.response.data.message });
    }
  }
)

export const fetchProductUserList = createAsyncThunk(
  "user/PRODUCT_LIST",
  async (params: any, { rejectWithValue }) => {
    try {
      const paramQuery = assemblyParameters(params);
      const res = await api.post(`${api_url}/v1/users/products/list?${paramQuery}`);
      return res.data.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.response.data.message });
    }
  }
)

const slice = createSlice({
  name: "user",
  initialState: {
    userList: [],
    userDetail: {},
    userResponse: {},
    userRequest: [],
    userProductList: [],
    status: "idle",
    error: "",
  },
  reducers: {
    clearStatus: (state: any) => {
      state.status = RES_IDLE;
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    //FETCH_ALL
    builder.addCase(fetchAllUser.pending, (state) => setPending(state));
    builder.addCase(fetchAllUser.fulfilled, (state, { payload }: any) => setUserList(state, payload));
    builder.addCase(fetchAllUser.rejected, (state, action) => setRejected(state, action));
    //FETCH_BY_ID
    builder.addCase(fetchUserById.pending, (state) => setPending(state));
    builder.addCase(fetchUserById.fulfilled, (state, { payload }: any) => setUserDetail(state, payload));
    builder.addCase(fetchUserById.rejected, (state, action) => setRejected(state, action));
    //CREATE
    builder.addCase(createUser.pending, (state) => setPending(state));
    builder.addCase(createUser.fulfilled, (state, { payload }: any) => setUserResponse(state, payload));
    builder.addCase(createUser.rejected, (state, action) => setRejected(state, action));
    //UPDATE
    builder.addCase(updateUser.pending, (state) => setPending(state));
    builder.addCase(updateUser.fulfilled, (state, { payload }: any) => setUserResponse(state, payload));
    builder.addCase(updateUser.rejected, (state, action) => setRejected(state, action));
    //DEACTIVATED
    builder.addCase(deactivateUser.pending, (state) => setPending(state));
    builder.addCase(deactivateUser.fulfilled, (state) => setSucceed(state));
    builder.addCase(deactivateUser.rejected, (state, action) => setRejected(state, action));
    //CREATE_REQUEST
    builder.addCase(createRequestUser.pending, (state) => setPending(state));
    builder.addCase(createRequestUser.fulfilled, (state) => setSucceed(state));
    builder.addCase(createRequestUser.rejected, (state, action) => setRejected(state, action));
    //FETCH_REQUEST
    builder.addCase(fetchRequestUser.pending, (state) => setPending(state));
    builder.addCase(fetchRequestUser.fulfilled, (state, { payload }: any) => setUserRequest(state, payload));
    builder.addCase(fetchRequestUser.rejected, (state, action) => setRejected(state, action));
    //APPROVE_REQUEST
    builder.addCase(approveRequestUser.pending, (state) => setPending(state));
    builder.addCase(approveRequestUser.fulfilled, (state) => setSucceed(state));
    builder.addCase(approveRequestUser.rejected, (state, action) => setRejected(state, action));
    //LIST_PRODUCT
    builder.addCase(fetchProductUserList.pending, (state) => setPending(state));
    builder.addCase(fetchProductUserList.fulfilled, (state, { payload }: any) => setUserProductList(state, payload));
    builder.addCase(fetchProductUserList.rejected, (state, action) => setRejected(state, action));
  }
})

export const { clearStatus } = slice.actions;

export default slice.reducer;

export const getUserList = createSelector(
  (state: any) => ({ userList: state.entities.user.userList }),
  state => state
)

export const getUser = createSelector(
  (state: any) => ({ user: state.entities.user }),
  state => state
)

export const getUserRequest = createSelector(
  (state: any) => ({ userRequest: state.entities.user.userRequest }),
  state => state
);

export const getUserProductList = createSelector(
  (state: any) => ({ userProductList: state.entities.user.userProductList }),
  state => state
)

const setUserProductList = (state: any, payload: any) => {
  state.userProductList = payload;
  state.status = RES_STATUS.SUCCEEDED;
  state.error = "";
}

const setUserList = (state: any, payload: any) => {
  state.userList = payload;
  state.status = RES_STATUS.SUCCEEDED;
  state.error = "";
};

const setUserDetail = (state: any, payload: any) => {
  state.userDetail = payload;
  state.status = RES_STATUS.SUCCEEDED;
  state.error = "";
}

const setUserResponse = (state: any, payload: any) => {
  state.userResponse = payload;
  state.status = RES_STATUS.SUCCEEDED;
  state.error = "";
}

const setUserRequest = (state: any, payload: any) => {
  state.userRequest = payload;
  state.status = RES_STATUS.SUCCEEDED;
  state.error = "";
}