import { Button, InputField } from '@components/common';
import { fetchTransaction } from '@shared/api';
import { CA, MONITORING, REPORTING, Transaction } from '@shared/constants';
import { initialValueFilterMonitoring } from '@shared/constants/dtos/initialValue.dtos';
import { formSearchMonitoring, optionStateDefault, transactionDate } from '@shared/constants/form/monitoring.form.constant';

import { convertDate, removeEmptyObject } from '@shared/functions';
import { IFormProps } from '@shared/interface/form.interface';
import { searchFormValidations } from '@shared/validations/search-form.validation';
import { Form, Formik } from 'formik';
import React, { Key, useEffect, useState } from 'react'
import { Alert, Card, Col, Row } from 'react-bootstrap';
import { DataTableMonitoringComponent } from '../../data-tables/data-table.component';

export const CommitComponent = (props: any) => {
  const { typeOrg, module } = props;
  const [formData, setFormData] = useState<any>([]);
  const [dataList, setDataList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [paramSearch, setParamSearch] = useState<any>(null);
  const title = (module === MONITORING ? "Monitoring" : "Reporting") + " " + (typeOrg === CA ? "CA" : "Partner");

  useEffect(() => {
    if (module === MONITORING) {
      setFormData([...formSearchMonitoring, optionStateDefault]);
    } else {
      setFormData([...formSearchMonitoring, optionStateDefault, transactionDate]);
    }
  }, [module]);

  useEffect(() => {
    setParamSearch(null);
    setPerPage(10);
    setPage(1);
    setDataList([]);
  }, [module, typeOrg]);


  useEffect(() => {
    if (paramSearch !== null) {
      fetchCommit(paramSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage, page, paramSearch]);

  const fetchCommit = async (temp: any) => {
    let body = {
      ...temp,
      page,
      limit: perPage,
      module: module,
      type: typeOrg,
      moduleType: Transaction?.COMMIT,
    }
    if (module === REPORTING) {
      body = { ...body, transaction_date: convertDate(temp.transaction_date), }
    }
    const data = await fetchTransaction(body);
    if (data?.status === 200) {
      setDataList(data?.data || []);
      setIsLoading(false);
      setErrMsg("");
    } else {
      setErrMsg(data?.data?.message || "Error");
      setIsLoading(false);
    }
  }

  const handleSubmit = async (e: any) => {
    const temp = removeEmptyObject(e);
    setIsLoading(true);
    if (Object.keys(temp).length === 0) {
      setErrMsg("Please filled at least 1 field");
      setIsLoading(false);
    } else {
      setParamSearch(temp);
    }
  }

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setPerPage(newPerPage);
    setPage(page);
  };

  return (
    <>
      <Row>
        <Col sm={12}>
          <Card>
            <Card.Header>
              <h3 style={{ color: typeOrg === CA ? "#90e8fb" : "#FF009E" }}>Commit {title}</h3>
            </Card.Header>
            <Card.Body>
              <Formik
                enableReinitialize={true}
                validationSchema={searchFormValidations(module)}
                initialValues={initialValueFilterMonitoring(paramSearch)}
                onSubmit={handleSubmit}
              >
                {({ errors }) => {
                  return (
                    <>
                      <Form >
                        <div className="form-row">
                          {formData.map((data: IFormProps, i: Key) => {
                            return (
                              <InputField
                                field={data}
                                errors={errors}
                                key={i}
                                width={6}
                              />
                            )
                          })}
                        </div>
                        {errMsg &&
                          <Alert className="mt-4" style={{ width: "fit-content" }} variant={"danger"} >{errMsg}</Alert>
                        }
                        <Button mode={typeOrg === CA ? "primary" : "tertiary"}>{isLoading ? "Loading..." : "Search"}</Button>
                      </Form>
                    </>
                  )
                }}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <DataTableMonitoringComponent
        title={`List Commit ${title}`}
        data={dataList?.data || []}
        type={typeOrg}
        loading={isLoading}
        paginationTotalRows={dataList?.total_row}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        moduleType={Transaction.COMMIT}
        module={module}
        paramSearch={paramSearch}
      />
    </>
  )
}
