import { Button, InputField } from "@components/common";
import { TableComponent } from "@components/tables/table.component";
import { postMonitoringTopup } from "@shared/api";
import { initialValueCashInVirgo } from "@shared/constants/dtos/initialValue.dtos";
import { formCashInVirgo } from "@shared/constants/form/cico.form.constant";
import { IFormProps } from "@shared/interface/form.interface";
import { Form, Formik } from "formik";
import React, { Key, useState } from "react";
import { Alert, Card, Col, Row } from "react-bootstrap";
import { columnCashInVirgo } from "../data-tables/columns.cico";
import { TopUpVirgoLog } from "./log.component";

export const CashInVirgoComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [listData, setListData] = useState<any>([]);
  const handleSubmit = async (e: any) => {
    setIsLoading(!isLoading);
    const body = {
      ...e,
      limit: 10,
      page: 1,
    };
    const res = await postMonitoringTopup(body);
    if (res?.status === 200) {
      const data = res?.data?.data;
      setListData(data ? data : []);
      setIsLoading(!isLoading);
    } else {
      setIsLoading(!isLoading);
      setErrMsg(res?.message);
    }
  };

  return (
    <>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <h3>Customer Top Up Virgo</h3>
            </Card.Header>
            <Card.Body>
              <Formik
                enableReinitialize={true}
                // validationSchema={searchFormValidations(module)}
                initialValues={initialValueCashInVirgo(null)}
                onSubmit={handleSubmit}
              >
                {({ errors }) => {
                  return (
                    <>
                      <Form>
                        <div className="form-row">
                          {formCashInVirgo.map((data: IFormProps, i: Key) => {
                            return (
                              <InputField
                                field={data}
                                errors={errors}
                                key={i}
                                width={6}
                              />
                            );
                          })}
                        </div>
                        {errMsg && (
                          <Alert
                            className="mt-4"
                            style={{ width: "fit-content" }}
                            variant={"danger"}
                          >
                            {errMsg}
                          </Alert>
                        )}
                        <Button mode={"primary"}>
                          {isLoading ? "Loading..." : "Search"}
                        </Button>
                      </Form>
                    </>
                  );
                }}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <TableComponent
        data={listData}
        columns={columnCashInVirgo}
        title="Table Customer Top Up"
      />

      <TopUpVirgoLog />
    </>
  );
};
