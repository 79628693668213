import { HomeComponent } from '@components/module/home/home.component';
import { ADMIN_ROLE, Role } from '@shared/constants';
import { getAuthData } from '@store/entities/auth';
import React from 'react'
import { useSelector } from 'react-redux';

const HomePage = () => {
  const { auth } = useSelector(getAuthData);
  const role = auth.data?.UserRole[0]?.name;

  let renderPage;
  if (auth) {
    switch (role) {
      case Role.USER_SUPER_ADMIN:
        renderPage = <HomeComponent data={auth.data} />
        break;
      case ADMIN_ROLE:
        renderPage = <HomeComponent data={auth.data} />
        break;
      default:
        break;
    }
  }
  return <>{renderPage}</>;
}

export default HomePage;