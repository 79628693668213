import logo_aladin from '@shared/assets/images/ALGO_LOGO.png';
export const LoginLayout = (props: any) => {
  return (
    <>
      <div className="authincation vh-100">
        <div className="container h-75">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-6" style={{ textAlign: "center"}}>
              <img src={logo_aladin} alt="logo" width={"50%"} />
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">{props.children}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
