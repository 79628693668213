
import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import Table from 'react-data-table-component';

interface ITableComponent {
  title: string,
  columns: any[],
  data: any[],
  paginationTotalRows?: any;
  onChangePage?: any;
  onChangeRowsPerPage?: any;
  loading?: boolean;
  actions?: any;
}

export const TableComponent = (props: ITableComponent) => {
  const { columns, data, title, paginationTotalRows, onChangePage, onChangeRowsPerPage, loading, actions } = props;
  return (
    <Row>
      <Col xs={12} sm={12}>
        <Card>
          <Card.Body>
            <Table
              title={title}
              columns={columns}
              data={data}
              pagination
              paginationServer
              paginationTotalRows={paginationTotalRows}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              progressPending={loading}
              actions={actions}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}